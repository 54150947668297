import { UserRole } from "../constants/enum/UserRole";

export const User = {
  _id: "",
  name: "",
  email: "",
  password: "",
  password_confirmation: "",
  role: "",
  phases: new Array(),
};
