import { useEffect, useState } from "react";
import { useAppSelector } from "../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../services/stores/hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";

import { List } from "../../component/list/list";
import {
  setSearch,
  setSelectedId,
  setWarning,
  setPage,
  setAssignId,
  setAdvanceId,
  setLoading,
} from "../../services/stores/ProcessStore/actions";
import {
  advanceProcess,
  assignProcess,
  deleteThunk,
  fetchLawyerAssignedListThunk,
  fetchLawyerOpenedListThunk,
  fetchListThunk,
  fetchPreview,
} from "../../services/stores/ProcessStore/slice";
import Swal from "sweetalert2";
import { Alert } from "../../component/list/alert";

import Pusher from "pusher-js";
import {
  PUSHER_CLUSTER,
  PUSHER_KEY,
} from "../../constants/consts";

export function LawyerProcessList() {
  const { showWarning, loadingScreen, loadingSearch } = useAppSelector(
    (store) => store.processSlice.screen
  );

  const { pagination } = useAppSelector((store) => store.processSlice);
  const { search } = useAppSelector((store) => store.processSlice.list);

  const { assigned } = useAppSelector((store) => store.processSlice);
  const { opened } = useAppSelector((store) => store.processSlice);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [previewMessage, setPreviewMessage] = useState(false);
  const [assignMessage, setAssignMessage] = useState(false);
  const [assignId, setAssignIdState] = useState(null);

  const [advanceMessage, setAdvanceMessage] = useState(false);
  const [advanceId, setAdvanceIdState] = useState(null);

  useEffect(() => {
    dispatch(fetchLawyerAssignedListThunk({ force: true }));
    dispatch(fetchLawyerOpenedListThunk({ force: true }));
  }, []);

  return (
    <>
      <Alert
        confirmBtnText="OK"
        title="Atenção"
        showCancel={false}
        text={"A fase atual não possui preview"}
        show_warning={previewMessage}
        onCancel={() => {
          setPreviewMessage(false);
        }}
        onConfirm={() => {
          dispatch(setAssignId(assignId));
          dispatch(assignProcess()).then((result) => {
            if (result.type.includes("fulfilled")) {
              dispatch(fetchLawyerAssignedListThunk({ force: true }));
              dispatch(fetchLawyerOpenedListThunk({ force: true }));
            }
          });
          setPreviewMessage(false);
        }}
      ></Alert>

      <Alert
        confirmBtnText="Alocar processo"
        title="Atenção"
        text={"Deseja alocar o processo?"}
        show_warning={assignMessage}
        onCancel={() => {
          setAssignMessage(false);
        }}
        onConfirm={() => {
          dispatch(setAssignId(assignId));
          dispatch(assignProcess()).then((result) => {
            if (result.type.includes("fulfilled")) {
              dispatch(fetchLawyerAssignedListThunk({ force: true }));
              dispatch(fetchLawyerOpenedListThunk({ force: true }));
            }
          });
          setAssignMessage(false);
        }}
      ></Alert>
      <Alert
        confirmBtnText="Avançar processo"
        title="Atenção"
        text={"Deseja avançar o processo?"}
        show_warning={advanceMessage}
        onCancel={() => {
          setAdvanceMessage(false);
        }}
        onConfirm={() => {
          dispatch(setAdvanceId(advanceId));
          dispatch(advanceProcess()).then((result) => {
            if (result.type.includes("fulfilled")) {
              dispatch(fetchLawyerAssignedListThunk({ force: true }));
              dispatch(fetchLawyerOpenedListThunk({ force: true }));
            }
          });
          setAdvanceMessage(false);
        }}
      ></Alert>
      <div className="row">
        {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}
        <List
          hideSearch
          hideEdit
          hideDelete
          // searchbox ---------------------------------------------
          search_input_value={search}
          search_text="Pesquise pelo processo"
          onChangeSearchInput={(value) => {
            dispatch(setSearch(value));
          }}
          onSearchButtonClick={() => {
            dispatch(setPage(1));
            dispatch(fetchListThunk({ force: true }));
          }}
          onManagerActionClick={(id) => {
            setAssignIdState(id);
            setAssignMessage(true);
          }}
          title={"processos sem alocação"}
          not_found_message={"Nenhum processo encontrado"}
          button_text="Adicionar processo"
          button_link="/lawyer-processes/create"
          deleting_message={
            "Ao deletar este processo, ele não estará mais disponível no sistema"
          }
          onDeleteCancel={() => {
            dispatch(setWarning(false));
          }}
          onDeleteConfirm={() => {
            dispatch(deleteThunk());
            dispatch(setWarning(false));
          }}
          onDeleteActionClick={(id) => {
            dispatch(setSelectedId(id));
            dispatch(setWarning(true));
          }}
          onEditActionClick={(id) => {
            navigate("/lawyer-processes/" + id);
          }}
          onChangePagination={(value: number) => {
            dispatch(setPage(value));
            dispatch(fetchListThunk({ force: true }));
          }}
          show_warning={showWarning}
          loading_screen={false}
          loading_search={loadingSearch}
          items={opened.map((x) => {
            return {
              ...x,
              status:
                x.status == "OPENED"
                  ? "ABERTO"
                  : x.status == "ASSIGNED"
                  ? "ATRIBUIDO"
                  : x.status,
            };
          })}
          pagination={{
            last_page: pagination?.totalPages,
            current_page: pagination?.page,
          }}
          fields={[
            { name: "client.name", type: "text", header: "Cliente" },
            { name: "createdAt", type: "date", header: "Aberto em" },
            { name: "status", type: "text", header: "Status" },
            { name: "phase.name", type: "text", header: "Fase" },
            { name: "phase.product.name", type: "text", header: "Produto" },
          ]}
        ></List>
        <List
          hideSearch
          hideManager
          hideDelete
          showAuxiliar1
          showAuxiliar2
          onAuxiliar1ActionClick={(id) => {
            setAdvanceIdState(id);
            setAdvanceMessage(true);
          }}
          onAuxiliar2ActionClick={(id) => {
            const process = assigned.find((x) => x._id == id);
            if (process.phase.hasPreview === "1") {
              dispatch(fetchPreview({ id })).then((result) => {
                if (result.type.includes("fulfilled"))
                  dispatch(setLoading(true));
              });
              const pusher = new Pusher(PUSHER_KEY, {
                cluster: PUSHER_CLUSTER,
              });
              const assignedProcess = assigned.find((x) => x._id === id);
              console.log(assignedProcess);
              const channel1 = pusher.subscribe(
                "preview-template-" + assignedProcess.phase.key
              );
              channel1.bind("PreviewTemplate", function (data: any) {
                pusher.unsubscribe(
                  "preview-template-" + assignedProcess.phase.key
                );
                dispatch(setLoading(false));
                var win = window.open(data.url, "_blank");
                win?.focus();
              });
            } else setPreviewMessage(true);
          }}
          // searchbox ---------------------------------------------
          search_input_value={search}
          search_text="Pesquise pelo processo"
          onChangeSearchInput={(value) => {
            dispatch(setSearch(value));
          }}
          onSearchButtonClick={() => {
            dispatch(setPage(1));
            dispatch(fetchListThunk({ force: true }));
          }}
          title={"processos alocados"}
          not_found_message={"Nenhum processo encontrado"}
          button_text="Adicionar processo"
          button_link="/processes/create"
          deleting_message={
            "Ao deletar este processo, ele não estará mais disponível no sistema"
          }
          onDeleteCancel={() => {
            dispatch(setWarning(false));
          }}
          onDeleteConfirm={() => {
            dispatch(deleteThunk());
            dispatch(setWarning(false));
          }}
          onDeleteActionClick={(id) => {
            dispatch(setSelectedId(id));
            dispatch(setWarning(true));
          }}
          onEditActionClick={(id) => {
            navigate("/lawyer-processes/" + id);
          }}
          onChangePagination={(value: number) => {
            dispatch(setPage(value));
            dispatch(fetchListThunk({ force: true }));
          }}
          show_warning={showWarning}
          loading_screen={loadingScreen}
          loading_search={loadingSearch}
          items={assigned.map((x) => {
            return {
              ...x,
              contact:
                x.licensed?.name??"" +
                "<br/> <small><a href='mailto:'" +
                x.licensed?.email??"" +
                "'>" +
                x.licensed?.email??"" +
                "</a>",
            };
          })}
          pagination={{
            last_page: pagination?.totalPages,
            current_page: pagination?.page,
          }}
          fields={[
            { name: "client.name", type: "text", header: "Cliente" },
            { name: "contact", type: "text", header: "Licenciado" },
            { name: "createdAt", type: "date", header: "Aberto em" },
            { name: "status", type: "text", header: "Status" },
            { name: "phase.name", type: "text", header: "Fase" },
            { name: "phase.product.name", type: "text", header: "Produto" },
          ]}
        ></List>
      </div>
    </>
  );
}
