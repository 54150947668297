import { useState } from "react";
import logo from "../../../assets/images/logo.png";
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { UserRole } from "../../../constants/enum/UserRole";
import SidebarHeader from "./SidebarHeader";
import AdminMenu from "./RoleView/AdminMenu";
import SuperAdminMenu from "./RoleView/SuperAdminMenu";
import { authSlice } from "../../../services/stores/auth/slice";
import LawyerMenu from "./RoleView/LawyerMenu";
export const { logout } = authSlice.actions;

export default function SideBar() {
  const { me } = useAppSelector((store) => store.auth);
  const [shrunk, setShrunk] = useState(false);

  const handleShrunk = () => {
    setShrunk(!shrunk);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: shrunk ? "7.2rem" : "26rem",
        backgroundColor: "#FFF",
        borderRight: "1px solid #E0E0E0",
        paddingTop: "0.4rem",
        transition: "0.3s ease-in-out",
        zIndex: 2,
      }}
    >
      <SidebarHeader
        {...{
          shrunk,
          handleShrunk,
        }}
      />

      <hr
        style={{
          width: "100%",
          border: "1px solid #c4c4c4",
          margin: 0,
          marginTop: "0.6rem",
        }}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0.6rem 0",
          width: "100%",
          gap: "0.4rem",
          overflowY: "auto",
          height: "100%",
        }}
      >
        {me.role === UserRole.SUPER_USER ? (
          <SuperAdminMenu {...{ shrunk }} />
        ) : me.role === UserRole.ADMIN_USER ? (
          <AdminMenu {...{ shrunk }} />
        ) : me.role === UserRole.LAWYER_USER ? (
          <LawyerMenu {...{ shrunk }} />
        ) : (
          <></>
        )}
      </div>

      <hr
        style={{
          width: "100%",
          border: "1px solid #c4c4c4",
          margin: 0,
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "1rem 0",
          width: "100%",
          gap: "0.4rem",
          fontSize: "0.8rem",
          color: "#636363",
        }}
      >
        <img src={logo} alt="Logo" style={{ width: "6rem" }} />
        <span>Copyright © 2024</span>
      </div>
    </div>
  );
}
