import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { AuthAPI } from "../../../apis/AuthAPI";

export const register = createAsyncThunk(
  "auth/register",
  async (params: any = null, { getState, rejectWithValue }) => {
    var response = { token: null, me: null };
    try {
      const responseAuth = await AuthAPI.register(
        params.name,
        params.username,
        params.password,
      );
      const responseMe = await AuthAPI.me(responseAuth.token);
      response = { token: responseAuth.token, me: responseMe };
    } catch (e: any) {
      console.log(`Error register::createAsyncThunk ${e}`);
      return rejectWithValue({
        code: "FETCH_ERROR",
        message: e.message,
      });
    }
    return response;
  }
);

export const addRegisterReducers = (
  builder: ActionReducerMapBuilder<any>
) => {
  builder
    .addCase(register.pending, (state, _action) => {
      localStorage.removeItem("token");
      state.me = null;
      state.token = null;
      state.errorMessage = null;
      state.loading = true;
      state.logged = false;
    })
    .addCase(register.fulfilled, (state, action) => {
      const me: any = action.payload.me;
      localStorage.setItem("token", action.payload.token || "");
      state.me = me;
      state.token = action.payload.token;
      state.errorMessage = null;
      state.loading = false;
      state.logged = true;
      state.status = me.status ?? "CREATED";
    })
    .addCase(register.rejected, (state, action: any) => {
      localStorage.removeItem("token");
      state.me = null;
      state.token = null;

      state.errorMessage = action.payload.message ?? "UNDEFINED_REJECTION";
      state.loading = false;
      state.logged = false;
    });
};
