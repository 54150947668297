import { Field } from "./Field";
import { Product } from "./Product";

export const Phase = {
  _id: "",
  name: "",
  description: "",
  order: "",
  type: "",
  key: "",
  product: Product,
  dataFields: [],
  contentType: "",
  text: new Array(),
  hasPreview:"",
  documents: new Array(),
};
