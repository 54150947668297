import { modelSlice } from "./slice";

export const {
  setSearch,
  setWarning,
  setSelectedId,
  clearWindowState,
  setLoading,
  setErrorMessage,
  setPage,
  setModel,
  setName,
  setMatter,
  addKeyElementOption,
  removeKeyElementOption,
  setProcedence,
  setText,
  setConditions,
} = modelSlice.actions;
