import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { addAuthenticateReducers } from "./async/authenticateThunk";
import { addMeReducers } from "./async/meThunk";
import { initialState } from "./initialState";
import { addChangePasswordFirstLoginReducers } from "./async/changePasswordFirstLoginThunk";
import { addRegisterReducers } from "./async/registerThunk";

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setTokenAndMe: (state: any, action: PayloadAction<any>) => {
      const payload: any = action.payload;
      return {
        ...state,
        me: payload.me,
        token: payload.token,
        loading: false,
        logged: payload.token == null ? false : true,
        status: payload.me.status,
      };
    },
    setBlank: (state: any, action: PayloadAction<any>) => {
      return {
        ...state,
        blank: action.payload,
      };
    },
    logout: (_state: any) => {
      localStorage.removeItem("token");
      _state = { ...initialState };
      return { ..._state, logged: false, errorMessage: "" };
    },
  },
  extraReducers: (builder) => {
    addAuthenticateReducers(builder);
    addMeReducers(builder);
    addChangePasswordFirstLoginReducers(builder);
    addRegisterReducers(builder)
  },
});
