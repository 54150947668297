import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { handleThunkRejection } from "../utils";

export const fetchListFieldStoreFactory = (
  typePrefix: string,
  apiFunction: any,
  slice: any,
  field: any
) => {
  return createAsyncThunk(
    typePrefix,
    async (params: any = { force: false }, { getState, rejectWithValue }) => {
      const state: any = getState();
      console.log("state", state);
      const { token } = state.auth;
      if (
        !params.force &&
        state[slice][field] != null &&
        state[slice][field].length > 0
      )
        return state[slice][field];
      try {
        return await apiFunction(
          token,
          params.id ?? 1,
          params.search ?? null,
          false
        ).then((result: any) => {
          return { result, field };
        });
      } catch (e: any) {
        console.log(
          `Error fetchList::createAsyncThunk ${e.status} ${e.message}`
        );

        return handleThunkRejection(e, rejectWithValue);
      }
    }
  );
};
export const addFetchListFieldStoreFactoryReducer = (
  builder: ActionReducerMapBuilder<any>,
  fetchListFieldStoreFactory: any
) => {
  builder
    .addCase(fetchListFieldStoreFactory.pending, (state, _action) => {
      return state;
    })
    .addCase(fetchListFieldStoreFactory.fulfilled, (state, action) => {
      state[action.payload.field] = action.payload.result;
    })
    .addCase(fetchListFieldStoreFactory.rejected, (state, action) => {
      return state;
    });
};
