import SidebarItem from "../SidebarItem";

// icons
import {
  FaUsers,
  FaUserAlt,
  FaQuestion,
  FaUserPlus,
  FaUserTie,
} from "react-icons/fa";
import {
  MdOutlineQuestionAnswer,
  MdApartment,
  MdQuestionAnswer,
  MdListAlt,
  MdList,
  MdTask,
  MdDashboard,
  MdTimer,
} from "react-icons/md";

export default function SuperAdminMenu({ shrunk }) {
  return (
    <>
      <SidebarItem
        icon={<MdDashboard />}
        title="Dashboard"
        href="/"
        {...{ shrunk }}
      />

      <SidebarItem
        icon={<MdApartment />}
        title="Organizações"
        href="/organizations"
        {...{ shrunk }}
      />

      {/* Usuários */}
      <SidebarItem icon={<FaUsers />} title="Usuários" {...{ shrunk }}>
        <SidebarItem
          icon={<FaUserPlus />}
          title="Administradores"
          href="/users-admin"
          {...{ shrunk }}
        />
        <SidebarItem
          icon={<FaUserAlt />}
          title="Colaboradores"
          href="/users-colaborator"
          {...{ shrunk }}
        />
        <SidebarItem
          icon={<FaUserTie />}
          title="Professores"
          href="/users-teacher"
          {...{ shrunk }}
        />
        <SidebarItem
          icon={<FaUserTie />}
          title="Alunos"
          href="/users-student"
          {...{ shrunk }}
        />
      </SidebarItem>

      {/* Gestão Institucional */}
      <SidebarItem
        icon={<MdQuestionAnswer />}
        title="Gestão Institucional"
        {...{ shrunk }}
      >
        <SidebarItem
          icon={<FaUserPlus />}
          title="Unidades"
          href="/units"
          {...{ shrunk }}
        />
        <SidebarItem
          icon={<FaUserPlus />}
          title="Cursos"
          href="/courses"
          {...{ shrunk }}
        />
        <SidebarItem
          icon={<FaUserPlus />}
          title="Turmas"
          href="/turmas"
          {...{ shrunk }}
        />
        <SidebarItem
          icon={<FaUserPlus />}
          title="Matrículas"
          href="/registrations"
          {...{ shrunk }}
        />
      </SidebarItem>

      {/* Gestão de Prova */}
      <SidebarItem
        icon={<MdQuestionAnswer />}
        title="Gestão de Prova"
        {...{ shrunk }}
      >
        <SidebarItem
          icon={<MdOutlineQuestionAnswer />}
          title="Disciplinas"
          href="/disciplines"
          {...{ shrunk }}
        />
        <SidebarItem
          icon={<FaQuestion />}
          title="Questões"
          href="/questions"
          {...{ shrunk }}
        />
        <SidebarItem
          icon={<MdListAlt />}
          title="Provas"
          href="/exams"
          {...{ shrunk }}
        />
         <SidebarItem
          icon={<MdTimer />}
          title="Aplicações"
          href="/tests"
          {...{ shrunk }}
        />
        <SidebarItem
          icon={<MdList />}
          title="Padrões de Diagramação"
          href="/layouts_default_exams"
          {...{ shrunk }}
        />
        <SidebarItem
          icon={<MdList />}
          title="Diagramações"
          href="/layouts_exams"
          {...{ shrunk }}
        />
        <SidebarItem
          icon={<MdTask />}
          title="Demandas"
          href="/demands"
          {...{ shrunk }}
        />
      </SidebarItem>

      <SidebarItem
        icon={<FaUserPlus />}
        title="Logs"
        href="/logs"
        {...{ shrunk }}
      />
    </>
  );
}
