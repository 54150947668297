import { createSlice } from "@reduxjs/toolkit";

import { modelInitialState } from "./initialState";
import {
  listReducers,
  paginationReducers,
  screenReducers,
} from "../../../constants/consts";
import {
  addFetchListStoreFactoryReducer,
  fetchListStoreFactory,
} from "../_stores/fetchListStore";
import { UsersAPI } from "../../apis/UsersAPI";
import {
  addFetchIdStoreFactoryReducer,
  fetchIdStoreFactory,
} from "../_stores/fetchIdStore";
import {
  addDeleteStoreFactoryReducer,
  deleteStoreFactory,
} from "../_stores/deleteStore";
import {
  addSaveStoreFactoryReducer,
  saveStoreFactory,
} from "../_stores/saveStore";
import {
  addUpdateStoreFactoryReducer,
  updateStoreFactory,
} from "../_stores/updateStore";
import {
  addUploadFileStoreFactoryReducer,
  uploadFileStoreFactory,
} from "../_stores/uploadFileStore";

const preSaveValidate = function (model: any) {
  if (model.password === model.password_confirmation) return { error: false };
  return {
    error: true,
    e: {
      errors: [
        {
          code: "ValidationError",
          data: null,
          message: "Senha não confere",
        },
      ],
    },
  };
};

export const uploadFileThunk = uploadFileStoreFactory(
  "user-admin/upload",
  UsersAPI.uploadFile,
  "userAdmin"
);
export const fetchListThunk = fetchListStoreFactory(
  "user-admin/list",
  UsersAPI.getAll,
  "userAdmin"
);
export const fetchIdThunk = fetchIdStoreFactory(
  "user-admin/get",
  UsersAPI.getById,
  "userAdmin"
);
export const deleteThunk = deleteStoreFactory(
  "user-admin/delete",
  UsersAPI.delete,
  "userAdmin"
);
export const saveThunk = saveStoreFactory(
  "user-admin/save",
  UsersAPI.save,
  "userAdmin",
  preSaveValidate
);
export const updateThunk = updateStoreFactory(
  "user-admin/update",
  UsersAPI.update,
  "userAdmin",
  preSaveValidate
);

export const modelSlice = createSlice({
  name: "user-admin",
  initialState: modelInitialState,
  reducers: {
    setName: (state, action) => {
      return { ...state, model: { ...state.model, name: action.payload } };
    },
    setRole: (state, action) => {
      return { ...state, model: { ...state.model, role: action.payload } };
    },
    setEmail: (state, action) => {
      return { ...state, model: { ...state.model, email: action.payload } };
    },
    setPassword: (state, action) => {
      return { ...state, model: { ...state.model, password: action.payload } };
    },
    setConfirmPassword: (state, action) => {
      return {
        ...state,
        model: { ...state.model, password_confirmation: action.payload },
      };
    },

    addPhase: (state, action) => {
      const item = { ...action.payload };
      state.model.phases.push(item);
    },
    removePhase: (state, action) => {
      const idx = state.model.phases.findIndex(
        (x) => x._id === action.payload._id
      );
      state.model.phases.splice(idx, 1);
    },

    setPage: paginationReducers.setPage,

    setLoading: screenReducers.setLoading,
    setErrorMessage: screenReducers.setErrorMessage,
    setWarning: screenReducers.setWarning,

    setChildVisible: listReducers.setChildVisible,
    setSelectedId: listReducers.setSelectedId,
    setSearch: listReducers.setSearch,
    clearWindowState: (state) => {
      return modelInitialState;
    },
  },
  extraReducers: (builder) => {
    addFetchListStoreFactoryReducer(builder, fetchListThunk);
    addFetchIdStoreFactoryReducer(builder, fetchIdThunk);
    addDeleteStoreFactoryReducer(builder, deleteThunk);
    addSaveStoreFactoryReducer(builder, saveThunk);
    addUpdateStoreFactoryReducer(builder, updateThunk);
    addUploadFileStoreFactoryReducer(builder, uploadFileThunk);
  },
});
