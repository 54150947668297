import { modelSlice } from "./slice";

export const {
  setSearch,
  setWarning,
  setSelectedId,
  clearWindowState,
  setLoading,
  setName,
  setDescription,
  setPhase,
  setErrorMessage,
  setPage,
  setModel,
  setType,
  setPreText,
  setKey,
  setContentType,
  setOptions,
  addOptions,
  removeOption,
  addMultivalueField,
  removeMultivalueField,
  setMultivalueFields,
  setCountField,
  setIsRepeated,
  setKeyElement
} = modelSlice.actions;
