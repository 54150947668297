import { createSlice } from "@reduxjs/toolkit";

import { modelInitialState } from "./initialState";
import {
  listReducers,
  paginationReducers,
  screenReducers,
} from "../../../constants/consts";

import { ProductAPI } from "../../apis/ProductAPI";
import {
  addFetchListStoreFactoryReducer,
  fetchListStoreFactory,
} from "../_stores/fetchListStore";
import {
  addFetchIdStoreFactoryReducer,
  fetchIdStoreFactory,
} from "../_stores/fetchIdStore";
import {
  addDeleteStoreFactoryReducer,
  deleteStoreFactory,
} from "../_stores/deleteStore";
import {
  addSaveStoreFactoryReducer,
  saveStoreFactory,
} from "../_stores/saveStore";
import {
  addUpdateStoreFactoryReducer,
  updateStoreFactory,
} from "../_stores/updateStore";
import { addFetchListFieldStoreFactoryReducer, fetchListFieldStoreFactory } from "../_stores/fetchListFieldStore";

export const fetchListThunk = fetchListStoreFactory(
  "product-slice/list",
  ProductAPI.getAll,
  "productSlice"
);
export const fetchCoverProductsThunk = fetchListFieldStoreFactory(
  "product-slice/list-cover-products",
  ProductAPI.getCoverProducts,
  "productSlice",
  'coverProducts'
);
export const fetchIdThunk = fetchIdStoreFactory(
  "product-slice/get",
  ProductAPI.getById,
  "productSlice"
);
export const deleteThunk = deleteStoreFactory(
  "product-slice/delete",
  ProductAPI.delete,
  "productSlice"
);
export const saveThunk = saveStoreFactory(
  "product-slice/save",
  ProductAPI.save,
  "productSlice",
);
export const updateThunk = updateStoreFactory(
  "product-slice/update",
  ProductAPI.update,
  "productSlice",
);

export const modelSlice = createSlice({
  name: "product-slice",
  initialState: modelInitialState,
  reducers: {
    setName: (state, action) => {
      return { ...state, model: { ...state.model, name: action.payload } };
    },
    setKey: (state, action) => {
      return { ...state, model: { ...state.model, key: action.payload } };
    },
    setModel: (state: any, action: { payload: any }) => {
      return {
        ...state,
        model: action.payload,
      };
    },
    addMatter: (state, action) => {
      const item = { ...action.payload };
      state.model.matters.push(item);
    },
    removeMatter: (state, action) => {
      const idx = state.model.matters.findIndex(
        (x) => x._id === action.payload._id
      );
      state.model.matters.splice(idx, 1);
    },

    setPage: paginationReducers.setPage,
    setLoading: screenReducers.setLoading,
    setErrorMessage: screenReducers.setErrorMessage,
    setWarning: screenReducers.setWarning,

    setChildVisible: listReducers.setChildVisible,
    setSelectedId: listReducers.setSelectedId,
    setSearch: listReducers.setSearch,
    clearWindowState: (_: any) => {
      return modelInitialState;
    },
  },
  extraReducers: (builder) => {
    addFetchListFieldStoreFactoryReducer(builder, fetchCoverProductsThunk)
    addFetchListStoreFactoryReducer(builder, fetchListThunk);
    addFetchIdStoreFactoryReducer(builder, fetchIdThunk);
    addDeleteStoreFactoryReducer(builder, deleteThunk);
    addSaveStoreFactoryReducer(builder, saveThunk);
    addUpdateStoreFactoryReducer(builder, updateThunk);
  },
});
