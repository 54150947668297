// src/apis/productAPI.js

import { API_URI } from "../../constants/consts";
import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const AuthAPI = {
  changePasswordFirstLogin: async function (
    token: string,
    password: string,
    cancel = false
  ) {
    return api
      .request({
        url: `${API_URI}/change_password_first_login`,
        method: "POST",
        headers: {
          Authorization: token,
        },
        data: { password: password },
        // signal: cancel
        //   ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        return response.data;
      });
  },

  authorize: async function (email: string, password: string, cancel = false) {
    return api
      .request({
        url: `${API_URI}/auth`,
        method: "POST",
        data: { email, password },
        // signal: cancel
        //   ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        return response.data;
      });
  },

  register: async function (name: string, email: string, password: string, cancel = false) {
    return api
      .request({
        url: `${API_URI}/register`,
        method: "POST",
        data: {  name, email, password },
        // signal: cancel
        //   ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        return response.data;
      });
  },

  me: async function (token: string, cancel = false) {
    return api
      .request({
        url: `${API_URI}/me`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data;
        return payload.data;
      });
  },
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(AuthAPI);
