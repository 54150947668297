import { createSlice } from "@reduxjs/toolkit";

import { modelInitialState } from "./initialState";
import {
  listReducers,
  paginationReducers,
  screenReducers,
} from "../../../constants/consts";

import { FieldAPI } from "../../apis/FieldAPI";
import {
  addFetchListStoreFactoryReducer,
  fetchListStoreFactory,
} from "../_stores/fetchListStore";
import {
  addFetchIdStoreFactoryReducer,
  fetchIdStoreFactory,
} from "../_stores/fetchIdStore";
import {
  addDeleteStoreFactoryReducer,
  deleteStoreFactory,
} from "../_stores/deleteStore";
import {
  addSaveStoreFactoryReducer,
  saveStoreFactory,
} from "../_stores/saveStore";
import {
  addUpdateStoreFactoryReducer,
  updateStoreFactory,
} from "../_stores/updateStore";
import {
  fetchListFieldStoreFactory,
  addFetchListFieldStoreFactoryReducer,
} from "../_stores/fetchListFieldStore";


export const fetchListFieldStoreThunk = fetchListFieldStoreFactory(
  "field-slice/list-fields",
  FieldAPI.getAll,
  "fieldSlice",
  "fields"
);

export const fetchCoverFieldsThunk = fetchListFieldStoreFactory(
  "field-slice/list-cover-fields",
  FieldAPI.getCoverFields,
  "fieldSlice",
  'coverProducts'
);

export const fetchListThunk = fetchListStoreFactory(
  "field-slice/list",
  FieldAPI.getAll,
  "fieldSlice"
);
export const fetchIdThunk = fetchIdStoreFactory(
  "field-slice/get",
  FieldAPI.getById,
  "fieldSlice"
);
export const deleteThunk = deleteStoreFactory(
  "field-slice/delete",
  FieldAPI.delete,
  "fieldSlice"
);
export const saveThunk = saveStoreFactory(
  "field-slice/save",
  FieldAPI.save,
  "fieldSlice",
);
export const updateThunk = updateStoreFactory(
  "field-slice/update",
  FieldAPI.update,
  "fieldSlice",
);
export const modelSlice = createSlice({
  name: "field-slice",
  initialState: modelInitialState,
  reducers: {
    setName: (state, action) => {
      return { ...state, model: { ...state.model, name: action.payload } };
    },
    setIsRepeated: (state, action) => {
      return {
        ...state,
        model: { ...state.model, isRepeated: action.payload },
      };
    },
    setKeyElement: (state, action) => {
      return {
        ...state,
        model: { ...state.model, keyElement: action.payload },
      };
    },
    setDescription: (state, action) => {
      return {
        ...state,
        model: { ...state.model, description: action.payload },
      };
    },
    setPhase: (state, action) => {
      return { ...state, model: { ...state.model, phase: action.payload } };
    },
    setCountField: (state, action) => {
      return {
        ...state,
        model: { ...state.model, countField: action.payload },
      };
    },
    setType: (state, action) => {
      return { ...state, model: { ...state.model, type: action.payload } };
    },
    setContentType: (state, action) => {
      return {
        ...state,
        model: { ...state.model, contentType: action.payload },
      };
    },
    addMultivalueField: (state, action) => {
      const item = { ...action.payload };
      state.model.multivalueFields.push(item);
    },
    removeMultivalueField: (state, action) => {
      const idx = state.model.multivalueFields.findIndex(
        (x) => x._id === action.payload._id
      );
      state.model.multivalueFields.splice(idx, 1);
    },
    setMultivalueFields: (state, action) => {
      return {
        ...state,
        model: { ...state.model, multivalueFields: action.payload },
      };
    },

    addOptions: (state, action) => {
      const item = { ...action.payload };
      state.model.options.push(item);
    },
    removeOption: (state, action) => {
      const idx = state.model.options.findIndex(
        (x) => x._id === action.payload._id
      );
      state.model.options.splice(idx, 1);
    },
    setOptions: (state, action) => {
      return { ...state, model: { ...state.model, options: action.payload } };
    },
    setPreText: (state, action) => {
      return { ...state, model: { ...state.model, preText: action.payload } };
    },
    setKey: (state, action) => {
      return { ...state, model: { ...state.model, key: action.payload } };
    },
    setModel: (state: any, action: { payload: any }) => {
      return {
        ...state,
        model: action.payload,
      };
    },
    setPage: paginationReducers.setPage,
    setLoading: screenReducers.setLoading,
    setErrorMessage: screenReducers.setErrorMessage,
    setWarning: screenReducers.setWarning,

    setChildVisible: listReducers.setChildVisible,
    setSelectedId: listReducers.setSelectedId,
    setSearch: listReducers.setSearch,
    clearWindowState: (_: any) => {
      return modelInitialState;
    },
  },
  extraReducers: (builder) => {
    addFetchListFieldStoreFactoryReducer(builder, fetchCoverFieldsThunk)
    addFetchListFieldStoreFactoryReducer(builder, fetchListFieldStoreThunk);
    addFetchListStoreFactoryReducer(builder, fetchListThunk);
    addFetchIdStoreFactoryReducer(builder, fetchIdThunk);
    addDeleteStoreFactoryReducer(builder, deleteThunk);
    addSaveStoreFactoryReducer(builder, saveThunk);
    addUpdateStoreFactoryReducer(builder, updateThunk);
  },
});
