import { useEffect, useState } from "react";

// unidades
import { useAppSelector } from "../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../services/stores/hooks/useAppDispatch";
import {
  Button,
  Form,
  FormControl,
  InputGroup,
  Card,
  Row,
  Col,
} from "react-bootstrap";
import { Attention } from "../../component/base/Attention";
import { useNavigate, useParams } from "react-router-dom";
import {
  setName,
  setErrorMessage,
  clearWindowState,
  setMatter,
  setProcedence,
  setText,
  setConditions,
  addKeyElementOption,
  removeKeyElementOption,
} from "../../services/stores/JurisprudenceStore/actions";
import { RiArrowGoBackLine } from "react-icons/ri";
import {
  fetchIdThunk,
  saveThunk,
  updateThunk,
} from "../../services/stores/JurisprudenceStore/slice";

import { fetchListThunk as fetchMatterListThunk } from "../../services/stores/MatterStore/slice";
import { fetchListThunk as fetchKeyElementsListThunk } from "../../services/stores/KeyElementStore/slice";

import { SaveRounded } from "@material-ui/icons";
import Select from "react-select/async";
import { SelectOption } from "../../constants/consts";

import AsyncSelect from "react-select/async";
import { FaTrashAlt } from "react-icons/fa";
var debounce = require("lodash.debounce");

export function JurisprudenceForm(props: { editMode?: boolean }) {
  const procedenceTypes: readonly SelectOption[] = [
    { label: "Favorável", value: "FAVOR" },
    { label: "Desfavorável", value: "CONTRA" },
  ];

  const [selectedKeyElement, setSelectedKeyElement] = useState({ _id: null });
  const searchKeyElement = (query: any, callback: any) => {
    dispatch(fetchKeyElementsListThunk({ force: true, search: query })).then(
      (result) => {
        callback(result.payload);
      }
    );
  };

  const [elementList, setElementList] = useState(new Array());
  const [selectedKeyOption, setSelectedKeyOption] = useState({ id: null });
  const [keyElementOptions, setKeyElementOptions] = useState([]);

  const {
    errorMessages,
    loadingSave,
    loadingScreen,
    successMessage,
    loadingImage,
  } = useAppSelector((store) => store.jurisprudenceSlice.screen);
  const { name, matter, procedence, text, conditions, keyElements } =
    useAppSelector((store) => store.jurisprudenceSlice.model);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const selectedMatter = useAppSelector(
    (store) => store.jurisprudenceSlice.model.matter
  );

  const searchMatter = (query: any, callback: any) => {
    dispatch(fetchMatterListThunk({ force: true, search: query })).then(
      (result) => {
        callback(result.payload);
      }
    );
  };

  const saveContent = (e: any) => {
    e.preventDefault();
    if (props.editMode) {
      dispatch(updateThunk()).then((result) => {
        if (result.type.includes("fulfilled")) navigate(-1);
      });
    } else {
      dispatch(saveThunk()).then((result) => {
        if (result.type.includes("fulfilled")) navigate(-1);
      });
    }
  };

  useEffect(() => {
    if (props.editMode) {
      dispatch(fetchIdThunk({ id: params.id, force: true }));
    }

    dispatch(fetchKeyElementsListThunk({ force: true })).then((result) => {
      setElementList(result.payload);
    });

    return () => {
      dispatch(clearWindowState());
    };
  }, []);
  return (
    <div className="row">
      <div className="col-12">
        <div className="py-5 px-3">
          <div className="card-body" id="card" style={{ overflow: "visible" }}>
            <h4
              className="mt-0 header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              {props.editMode ? "Editar" : "Cadastrar"} jurisprudência
            </h4>
            <p style={{ fontSize: "1rem", color: "black" }}>
              {props.editMode
                ? "Edite uma jurisprudência do seu sistema"
                : "Cadastre as jurisprudências do seu sistema"}
            </p>
            <br />

            {loadingScreen == false && (
              <div>
                {successMessage && successMessage != "" && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-success alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(clearWindowState())}
                      ></button>

                      <p style={{ marginBottom: 0, whiteSpace: "pre" }}>
                        {successMessage}
                      </p>
                    </div>
                    <br />
                  </div>
                )}
                {errorMessages && errorMessages.length > 0 && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(setErrorMessage(""))}
                      ></button>
                      {errorMessages.map((error, i) => (
                        <p key={i} style={{ marginBottom: 0 }}>
                          {error}
                        </p>
                      ))}
                    </div>
                    <br />
                  </div>
                )}
                <Form
                  onSubmit={(e) => saveContent(e)}
                  style={{ position: "relative" }}
                >
                  {/* <div className="row"> */}

                  <InputGroup className="col-lg">
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>
                        Nome <Attention />
                      </Form.Label>
                      <FormControl
                        required
                        placeholder="Nome"
                        value={name}
                        onChange={(e) => dispatch(setName(e.target.value))}
                        // required
                        // title="Coloque o nome completo do usuário"
                      />
                    </div>
                  </InputGroup>
                  <div className="row">
                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Matéria <Attention />
                        </Form.Label>
                        <AsyncSelect
                          getOptionLabel={(option: any) => option.name}
                          getOptionValue={(option: any) => option._id}
                          placeholder="Digite parte do nome da matéria..."
                          onChange={(e: any) => {
                            dispatch(setMatter(e));
                          }}
                          cacheOptions
                          value={selectedMatter}
                          loadOptions={debounce(searchMatter, 250)}
                        />
                      </div>
                    </InputGroup>
                  </div>
                  <Card
                    style={{
                      padding: 10,
                      paddingBottom: 20,
                    }}
                  >
                    <div className="row">
                      <div className="col">
                        <InputGroup className="col-lg">
                          <div className="flex fdcolumn flex1 margin-bottom">
                            <Form.Label>
                              Elementos-chave <Attention />
                            </Form.Label>
                            <AsyncSelect
                              getOptionLabel={(option: any) => option.name}
                              getOptionValue={(option: any) => option}
                              placeholder="Digite parte do nome da fase..."
                              onChange={(e: any) => {
                                setSelectedKeyElement(e);
                                setKeyElementOptions(
                                  e.keyOptions.map((x: any) => ({
                                    id: x,
                                    label: x,
                                  }))
                                );
                              }}
                              cacheOptions
                              value={selectedKeyElement}
                              loadOptions={debounce(searchKeyElement, 250)}
                            />
                          </div>
                        </InputGroup>
                      </div>
                    </div>
                    {selectedKeyElement && (
                      <div className="row">
                        <div className="col">
                          <InputGroup className="col-lg">
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Opção <Attention />
                              </Form.Label>
                              <Select
                                getOptionLabel={(option: any) => option.label}
                                getOptionValue={(option: any) => option.id}
                                placeholder="Escolha a opção..."
                                onChange={(e: any) => {
                                  setSelectedKeyOption(e);
                                }}
                                value={selectedKeyOption}
                                defaultOptions={keyElementOptions}
                              />
                            </div>
                          </InputGroup>
                        </div>
                      </div>
                    )}
                    <div className="flex flex1 margin-bottom">
                      <Button
                        onClick={() => {
                          dispatch(
                            addKeyElementOption({
                              keyElement: selectedKeyElement._id,
                              option: selectedKeyOption.id,
                            })
                          );
                        }}
                      >
                        Adicionar
                      </Button>
                    </div>
                    {keyElements?.map((x: any, key: any) => (
                      <div key={key} className="row">
                        <Row>
                          <Col style={{ paddingBottom: "0.5em" }}>
                            {
                              elementList.find(
                                (y: any) => y._id == x.keyElement
                              )?.name
                            }
                            - {x.option}
                            <span
                              onClick={() => {
                                dispatch(removeKeyElementOption(x));
                              }}
                              className="inline-flex align-center jcc bg-danger icon"
                              style={{
                                marginLeft: "1em",
                                width: "2em",
                                height: "2em",
                                borderRadius: "7px",
                              }}
                              title="Gerenciar"
                            >
                              <FaTrashAlt color="white" />
                            </span>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </Card>
                  <InputGroup className="col-lg">
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>
                        Procedência <Attention />
                      </Form.Label>
                      <Select
                        getOptionLabel={(option: any) => option.label}
                        getOptionValue={(option: any) => option.value}
                        placeholder="Procedência"
                        onChange={(e: any) => {
                          dispatch(setProcedence(e.value));
                        }}
                        cacheOptions
                        defaultOptions={procedenceTypes}
                        value={procedenceTypes.find(
                          (x) => x.value == procedence
                        )}
                      />
                    </div>
                  </InputGroup>
                  <InputGroup className="col-lg">
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>
                        Condicionantes <Attention />
                      </Form.Label>
                      <FormControl
                        required
                        rows={5}
                        as="textarea"
                        placeholder="Condicionantes da jurisprudência"
                        value={conditions}
                        onChange={(e) =>
                          dispatch(setConditions(e.target.value))
                        }
                        // required
                        // title="Coloque o nome completo do usuário"
                      />
                    </div>
                  </InputGroup>
                  <InputGroup className="col-lg">
                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>
                        Texto <Attention />
                      </Form.Label>
                      <FormControl
                        required
                        as="textarea"
                        rows={20}
                        placeholder="Texto da Jurisprudência"
                        value={text}
                        onChange={(e) => dispatch(setText(e.target.value))}
                        // required
                        // title="Coloque o nome completo do usuário"
                      />
                    </div>
                  </InputGroup>

                  {/* </div> */}
                  {!loadingSave && (
                    <div className="w100 inline-flex jcsb mt-3">
                      <Button
                        variant="secondary"
                        className="flex align-center jcc"
                        onClick={() => navigate(-1)}
                      >
                        <RiArrowGoBackLine style={{ marginRight: 10 }} /> Voltar
                      </Button>
                      <div className="w100 inline-flex justify-content-end margin-top-1">
                        <Button
                          variant="success"
                          type="submit"
                          disabled={loadingImage == true}
                          className="flex align-center jcc"
                        >
                          <SaveRounded style={{ marginRight: 10 }} /> Salvar
                        </Button>
                      </div>
                    </div>
                  )}

                  {loadingSave == true && (
                    <div className="row">
                      {" "}
                      <div className="col-12 d-flex justify-content-center align-items-center margin-top-1">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        ></div>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            )}

            {loadingScreen && (
              <div className="row">
                {" "}
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
