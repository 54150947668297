import React, { useRef, useState } from "react";
import { Button, FormControl as Input } from "react-bootstrap";
import { AiOutlineSearch } from "react-icons/ai";

const Search = React.forwardRef((props: any, ref) => {
  return (
    <div
      className={`flex fdrow ${props.className}`}
      style={{ flexBasis: props.flex_basis ?? "50%", height: "max-content" }}
    >
      <Input
        ref={useRef(null)}
        placeholder={props.placeholder}
        value={props.inputValue ?? ""}
        className="wauto rounded-left flex1 margin-0"
        style={{ borderRight: "none" }}
        onChange={(e) => {
          if (!props.WaitConfirm)
            props.ValueChange && props.ValueChange(e.target.value ?? "");
        }}
      />
      {(props.hideSearch === undefined || props.hideSearch === false) && (
        <Button
          className="bg-white rounded-right"
          style={{ borderLeft: "none", borderColor: "rgba(0, 0, 0, 0.125)" }}
          onClick={() =>
            props.ValueChange && props.ValueChange(props.input_value)
          }
        >
          <AiOutlineSearch color="black" />
        </Button>
      )}
    </div>
  );
});

export default Search;
