import SidebarItem from "../SidebarItem";

// icons
import {
  FaUsers,
  FaUserAlt,
  FaQuestion,
  FaUserPlus,
  FaUserTie,
} from "react-icons/fa";
import {
  MdOutlineQuestionAnswer,
  MdApartment,
  MdQuestionAnswer,
  MdListAlt,
  MdList,
  MdTask,
  MdDashboard,
  MdTimer,
} from "react-icons/md";

export default function LawyerMenu({ shrunk }) {
  return (
    <>
    <SidebarItem
      icon={<MdDashboard />}
      title="Dashboard"
      href="/"
      {...{ shrunk }}
    />
    <SidebarItem
      icon={<FaUserPlus />}
      title="Processos"
      href="/lawyer-processes"
      {...{ shrunk }}
    />
  </>
  );
}
