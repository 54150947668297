import { createSlice } from "@reduxjs/toolkit";

import { modelInitialState } from "./initialState";
import {
  listReducers,
  paginationReducers,
  screenReducers,
} from "../../../constants/consts";

import { JurisprudenceAPI } from "../../apis/JurisprudenceAPI";
import {
  addFetchListStoreFactoryReducer,
  fetchListStoreFactory,
} from "../_stores/fetchListStore";
import {
  addFetchIdStoreFactoryReducer,
  fetchIdStoreFactory,
} from "../_stores/fetchIdStore";
import {
  addDeleteStoreFactoryReducer,
  deleteStoreFactory,
} from "../_stores/deleteStore";
import {
  addSaveStoreFactoryReducer,
  saveStoreFactory,
} from "../_stores/saveStore";
import {
  addUpdateStoreFactoryReducer,
  updateStoreFactory,
} from "../_stores/updateStore";

const preSaveValidate = function (model: any) {
  if (model.password === model.password_confirmation) return { error: false };
  return {
    error: true,
    e: {
      errors: [
        {
          code: "ValidationError",
          data: null,
          message: "Senha não confere",
        },
      ],
    },
  };
};

export const fetchListThunk = fetchListStoreFactory(
  "jurisprudence-slice/list",
  JurisprudenceAPI.getAll,
  "jurisprudenceSlice"
);
export const fetchIdThunk = fetchIdStoreFactory(
  "jurisprudence-slice/get",
  JurisprudenceAPI.getById,
  "jurisprudenceSlice"
);
export const deleteThunk = deleteStoreFactory(
  "jurisprudence-slice/delete",
  JurisprudenceAPI.delete,
  "jurisprudenceSlice"
);
export const saveThunk = saveStoreFactory(
  "jurisprudence-slice/save",
  JurisprudenceAPI.save,
  "jurisprudenceSlice",
  preSaveValidate
);
export const updateThunk = updateStoreFactory(
  "jurisprudence-slice/update",
  JurisprudenceAPI.update,
  "jurisprudenceSlice",
  preSaveValidate
);

export const modelSlice = createSlice({
  name: "jurisprudence-slice",
  initialState: modelInitialState,
  reducers: {
    setName: (state, action) => {
      return { ...state, model: { ...state.model, name: action.payload } };
    },
    setMatter: (state, action) => {
      return { ...state, model: { ...state.model, matter: action.payload } };
    },
    addKeyElementOption: (state, action) => {
      const item = { ...action.payload };
      state.model.keyElements.push(item);
    },
    removeKeyElementOption: (state, action) => {
      const idx = state.model.keyElements.findIndex(
        (x) => x._id === action.payload._id
      );
      state.model.keyElements.splice(idx, 1);
    },
    setProcedence: (state, action) => {
      return {
        ...state,
        model: { ...state.model, procedence: action.payload },
      };
    },
    setText: (state, action) => {
      return { ...state, model: { ...state.model, text: action.payload } };
    },
    setConditions: (state, action) => {
      return {
        ...state,
        model: { ...state.model, conditions: action.payload },
      };
    },
    setModel: (state: any, action: { payload: any }) => {
      return {
        ...state,
        model: action.payload,
      };
    },
    setPage: paginationReducers.setPage,
    setLoading: screenReducers.setLoading,
    setErrorMessage: screenReducers.setErrorMessage,
    setWarning: screenReducers.setWarning,

    setChildVisible: listReducers.setChildVisible,
    setSelectedId: listReducers.setSelectedId,
    setSearch: listReducers.setSearch,
    clearWindowState: (_: any) => {
      return modelInitialState;
    },
  },
  extraReducers: (builder) => {
    addFetchListStoreFactoryReducer(builder, fetchListThunk);
    addFetchIdStoreFactoryReducer(builder, fetchIdThunk);
    addDeleteStoreFactoryReducer(builder, deleteThunk);
    addSaveStoreFactoryReducer(builder, saveThunk);
    addUpdateStoreFactoryReducer(builder, updateThunk);
  },
});
