import { useEffect } from "react";
import { useAppSelector } from "../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../services/stores/hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";

import { List } from "../../component/list/list";
import {
  setSearch,
  setSelectedId,
  setWarning,
  setPage,
} from "../../services/stores/ProductStore/actions";
import {
  deleteThunk,
  fetchListThunk,
} from "../../services/stores/ProductStore/slice";

export function GroupList() {
  const products = useAppSelector((store) => store.productSlice.list.all);
  const { showWarning, loadingScreen, loadingSearch } = useAppSelector(
    (store) => store.productSlice.screen
  );

  const { pagination } = useAppSelector((store) => store.productSlice);
  const { search } = useAppSelector((store) => store.productSlice.list);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchListThunk({ force: true })).then((result) => {});
  }, []);
  return (
    <div className="row">
      {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}
      <List
        hideManager
        // searchbox ---------------------------------------------
        search_input_value={search}
        search_text="Pesquise pelo nome do produto"
        onChangeSearchInput={(value) => {
          dispatch(setSearch(value));
        }}
        onSearchButtonClick={() => {
          dispatch(setPage(1));
          dispatch(fetchListThunk({ force: true }));
        }}
        // searchbox ---------------------------------------------
        // additionalButtons={[
        //   { label: "Importar CSV", eventHandler: (e: any) => onImportClick(e) },
        // ]}
        title={"produtos"}
        not_found_message={"Nenhum produto encontrado"}
        button_text="Adicionar produto"
        button_link="/products/create"
        deleting_message={
          "Ao deletar este produto, ele não estará mais disponível no sistema"
        }
        onDeleteCancel={() => {
          dispatch(setWarning(false));
        }}
        onDeleteConfirm={() => {
          dispatch(deleteThunk());
          dispatch(setWarning(false));
        }}
        onDeleteActionClick={(id) => {
          dispatch(setSelectedId(id));
          dispatch(setWarning(true));
        }}
        onEditActionClick={(id) => {
          navigate("/products/" + id);
        }}
        onChangePagination={(value: number) => {
          dispatch(setPage(value));
          dispatch(fetchListThunk({ force: true }));
        }}
        show_warning={showWarning}
        loading_screen={loadingScreen}
        loading_search={loadingSearch}
        items={products}
        pagination={{
          last_page: pagination?.totalPages,
          current_page: pagination?.page,
        }}
        fields={[
          { name: "name", type: "text", header: "Nome" },
          { name: "createdAt", type: "date", header: "Criado em" },
        ]}
      ></List>
    </div>
  );
}
