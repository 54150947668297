import {
  listState,
  paginationState,
  screenState,
} from "../../../constants/consts";
import { Matter } from "../../../model/Matter";

export const modelInitialState = {
  pagination: paginationState,
  screen: screenState,
  list: listState,
  model: Matter,
};
