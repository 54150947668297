import { useEffect, useState } from "react";

// unidades
import { NumericFormat } from "react-number-format";
import { useAppSelector } from "../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../services/stores/hooks/useAppDispatch";
import {
  Button,
  Card,
  Carousel,
  Form,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { Attention } from "../../component/base/Attention";
import { useNavigate, useParams } from "react-router-dom";
import {
  setErrorMessage,
  clearWindowState,
  setData,
  setPhaseContentField,
  addContentToChildren,
  setPhaseContentFieldKey,
  removeContentChild,
  setContentToModel,
} from "../../services/stores/ProcessStore/actions";
import { RiArrowGoBackLine, RiArticleLine } from "react-icons/ri";
import {
  fetchIdThunk,
  updateThunk,
  uploadFileThunk,
} from "../../services/stores/ProcessStore/slice";
import { SaveRounded } from "@material-ui/icons";
import Select from "react-select/async";
import Search from "../../component/base/Search";
import { FaMinusCircle, FaPlusCircle, FaTrashAlt } from "react-icons/fa";
import ExampleCarouselImage from "./ExampleCarrouselImage";
import { BsFileEarmarkSpreadsheet } from "react-icons/bs";
import { Divider } from "@material-ui/core";
import { isJsonString } from "../../constants/consts";

export function LawyerProcessForm(props: { editMode?: boolean }) {
  const {
    errorMessages,
    loadingSave,
    loadingScreen,
    successMessage,
    loadingImage,
  } = useAppSelector((store) => store.processSlice.screen);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const process = useAppSelector((store) => store.processSlice.model);
  const actualPhaseData = useAppSelector(
    (store) => store.processSlice.actualPhaseData
  );
  const pastPhaseData = useAppSelector(
    (store) => store.processSlice.pastPhaseData
  );

  const keyElementArray = useAppSelector(
    (store) => store.processSlice.keyElementArray
  );

  const [selectedMultivalue, setSelectedMultivalue] = useState(new Array());
  const [sanfonaStatus, setSanfonaStatus] = useState(new Array());
  const [showDocs, setShowDocs] = useState(false);
  const [showFilledFields, setShowFilledFields] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [file, setUpload] = useState(null);

  const saveContent = (e: any) => {
    if (file)
      dispatch(
        uploadFileThunk({
          schema: {
            id: params.id,
            properties: { custom_properties: { foo: "bar" } },
          },
          data: file,
        })
      ).then((result) => {
        if (result.type.includes("fulfilled")) navigate(-1);
      });
    else {
      e.preventDefault();
      dispatch(setContentToModel());
      dispatch(updateThunk()).then((result) => {
        if (result.type.includes("fulfilled")) navigate(-1);
      });
    }
  };
  useEffect(() => {
    dispatch(fetchIdThunk({ id: params.id, force: true }));
    return () => {
      dispatch(clearWindowState());
    };
  }, []);

  function getMultivalueContentCount(field: any) {
    return (
      pastPhaseData.find((x) => {
        return x?.field?._id === field.countField;
      })?.content -
      actualPhaseData?.phaseContent?.find((x) => {
        return x?.field?._id === field._id;
      })?.children.length
    );
  }

  return (
    <>
      {/* <div className="row">{JSON.stringify(actualPhaseData)}</div> */}
      <div className="row">
        <div className="col-12">
          <div className="py-5 px-3">
            <div hidden={!loadingScreen}></div>
            <div
              hidden={loadingScreen}
              className="card-body"
              id="card"
              style={{ overflow: "visible" }}
            >
              <h4
                className="mt-0 header-title"
                style={{ fontSize: "1.5rem", color: "black" }}
              >
                {process?.phase?.product?.name &&
                  `${process.phase.product.name} - ${process.phase.name}`}
              </h4>
              <h4
                className="mt-0 header-title"
                style={{ fontSize: "1.5rem", color: "black" }}
              >
                {`${process.client.name}`}
              </h4>
              <p style={{ fontSize: "1rem", color: "black" }}>
                {props.editMode
                  ? "Complete a fase do processo"
                  : "Cadastre os administradores do seu sistema"}
              </p>
              <br />

              {loadingScreen === false && (
                <div>
                  {successMessage && successMessage !== "" && (
                    <div
                      style={{
                        display: "flex",
                        marginBottom: 15,
                        width: "100%",
                      }}
                    >
                      <div
                        className="alert alert-success alert-dismissible fade show mb-0 w-100"
                        role="alert"
                      >
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                          onClick={() => dispatch(clearWindowState())}
                        ></button>

                        <p style={{ marginBottom: 0, whiteSpace: "pre" }}>
                          {successMessage}
                        </p>
                      </div>
                      <br />
                    </div>
                  )}
                  {errorMessages && errorMessages.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        marginBottom: 15,
                        width: "100%",
                      }}
                    >
                      <div
                        className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                        role="alert"
                      >
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                          onClick={() => dispatch(setErrorMessage(""))}
                        ></button>
                        {errorMessages.map((error, i) => (
                          <p key={i} style={{ marginBottom: 0 }}>
                            {error}
                          </p>
                        ))}
                      </div>
                      <br />
                    </div>
                  )}
                  <h4>
                    Documentos:&nbsp;&nbsp;
                    <span onClick={() => setShowDocs(!showDocs)}>
                      {showDocs && <FaMinusCircle></FaMinusCircle>}
                      {!showDocs && <FaPlusCircle></FaPlusCircle>}
                    </span>
                  </h4>
                  <div className="row">
                    {showDocs &&
                      process.media
                        ?.map((item) => item.category)
                        .filter(
                          (value, index, self) => self.indexOf(value) === index
                        )
                        .map((x: any, i) => (
                          <div key={i}>
                            <h5
                              style={{
                                padding: "8px",
                                paddingLeft: "20px",
                                margin: "auto",
                                border: "0px",
                                borderStyle: "solid",
                                borderColor: "black",
                                background: "var(--bs-secondary-bg)",
                                opacity: "0.5",
                              }}
                              onClick={() => {
                                const temp: any = { ...sanfonaStatus };
                                if (temp[i] !== undefined)
                                  temp[i] = 1 - temp[i];
                                else temp[i] = 0;
                                setSanfonaStatus(temp);
                              }}
                            >
                              {sanfonaStatus[i] === 0 ? "-" : "+"} {x}
                            </h5>
                            <div className="row" style={{ margin: "5px" }}>
                              {sanfonaStatus[i] === 0 &&
                                process.media
                                  ?.filter((value) => value.category === x)
                                  .map((media, i) => (
                                    <div
                                      className="col text-center"
                                      key={i}
                                      style={{
                                        maxWidth: "15%",
                                        margin: "10px",
                                        borderRadius: "10px",
                                        background: "var(--bs-secondary-bg)",
                                        padding: "10px",
                                        marginRight: "10px",
                                      }}
                                    >
                                      <a
                                        href={"#"}
                                        onClick={() => {
                                          var win = window.open(
                                            media.original_url,
                                            "_blank"
                                          );
                                          win?.focus();
                                        }}
                                        className="col text-center"
                                      >
                                        <RiArticleLine
                                          style={{ fontSize: "25px" }}
                                        />
                                        <br></br>
                                        <span style={{ fontSize: "0.85em" }}>
                                          {media.name}
                                        </span>
                                      </a>
                                    </div>
                                  ))}
                            </div>
                          </div>
                        ))}
                  </div>
                  &nbsp;
                  <Divider></Divider>
                  &nbsp;
                  <h4>Versões anteriores do documento final:</h4>
                  {process?.phase?.contentType === "TEXT" &&
                    process.media
                      // .filter(
                      // (media) => media.category === "Contrato Social PJ"
                      // )
                      .map((media, i) => (
                        <div className="row">
                          <div className="col">{media.name}</div>
                          <div className="col">
                            <span
                              onClick={() => {
                                alert(media.original_url);
                              }}
                              className="inline-flex align-center jcc bg-success icon"
                              style={{
                                width: "2em",
                                height: "2em",
                                borderRadius: "7px",
                                marginRight: "2px",
                              }}
                              title="Gerenciar"
                            >
                              <BsFileEarmarkSpreadsheet
                                size={17}
                                color="white"
                              />
                            </span>
                          </div>
                        </div>
                      ))}
                  {process?.phase?.contentType === "TEXT" && (
                    <>
                      &nbsp;
                      <Divider></Divider>
                      &nbsp;
                      <InputGroup
                        style={{ border: "1px", borderColor: "black" }}
                        className="col-lg"
                      >
                        <div className="flex fdcolumn flex1 margin-bottom">
                          <Form.Label>
                            Faça upload de uma nova versão do documento
                          </Form.Label>
                          <FormControl
                            type={"file"}
                            placeholder={""}
                            onChange={(e: any) => {
                              let target: any = e.target;
                              let file = target.files[0];
                              setUpload(file);
                            }}
                          />
                        </div>
                      </InputGroup>
                      <div className="w100 inline-flex jcsb mt-3">
                        <Button
                          variant="secondary"
                          className="flex align-center jcc"
                          onClick={() => navigate(-1)}
                        >
                          <RiArrowGoBackLine style={{ marginRight: 10 }} />{" "}
                          Voltar
                        </Button>
                        <div className="w100 inline-flex justify-content-end margin-top-1">
                          <Button
                            variant="success"
                            type="button"
                            disabled={loadingImage === true}
                            className="flex align-center jcc"
                            onClick={(e) => saveContent(e)}
                          >
                            <SaveRounded style={{ marginRight: 10 }} /> Salvar
                          </Button>
                        </div>
                      </div>
                    </>
                  )}
                  {process?.phase?.contentType === "TEXT" &&
                    process.media
                      .filter(
                        (media) => media.category === "Contrato Social PJ"
                      )
                      .map((media, i) => <></>)}
                  {process?.phase?.contentType !== "TEXT" && (
                    <div className="row">
                      <div className="col">
                        <h4
                          style={{
                            textAlign: "center",
                            padding: "5px",
                            marginTop: "30px",
                            paddingLeft: "20px",
                            borderRadius: "15px",
                            color: "white",
                            background: "#0000dd",
                          }}
                        >
                          Dados do Formulário
                        </h4>

                        {!loadingSave && (
                          <>
                            <div className="row">
                              <InputGroup
                                style={{ border: "1px", borderColor: "black" }}
                                className="col-lg"
                              >
                                <div className="flex fdcolumn flex1 margin-bottom">
                                  <Form.Label>
                                    Filtre campos do formulário
                                  </Form.Label>
                                  <Search
                                    flex_basis="100%"
                                    hideSearch
                                    inputValue={searchText}
                                    placeholder={"texto de pesquisa"}
                                    ValueChange={(value: any) => {
                                      setSearchText(value);
                                    }}
                                  ></Search>
                                </div>
                              </InputGroup>
                            </div>
                            {pastPhaseData
                              .filter((data: any) => {
                                if (searchText.length == 0) return 1;
                                if (
                                  data?.field?.name
                                    ?.toUpperCase()
                                    .includes(searchText.toUpperCase()) ||
                                  data?.content
                                    ?.toUpperCase()
                                    .includes(searchText.toUpperCase())
                                )
                                  return 1;
                                return 0;
                              })
                              .map((data: any, i) => (
                                <InputGroup key={i} className="col-lg">
                                  <div className="flex fdcolumn flex1 margin-bottom">
                                    <Form.Label>{data?.field?.name}</Form.Label>
                                    {data?.field?.contentType ===
                                      "OBJECTIVE" && (
                                      <FormControl
                                        disabled
                                        //value={JSON.stringify(content.field.options)}
                                        value={
                                          data?.field?.options.find(
                                            (x: any) => x._id === data?.content
                                          )?.name
                                        }
                                      />
                                    )}

                                    {data?.field?.contentType === "ARRAY" && (
                                      <>
                                        <div className="row">
                                          <Carousel
                                            interval={null}
                                            style={{ background: "lightgray" }}
                                          >
                                            {data.children.map(
                                              (
                                                x: any,
                                                i: number,
                                                array: any[]
                                              ) => {
                                                if (i % 4 == 0) {
                                                  return (
                                                    <Carousel.Item>
                                                      <div className="row">
                                                        {array
                                                          .filter(
                                                            (x, i2) =>
                                                              i2 >= i &&
                                                              i2 < i2 + 3
                                                          )
                                                          .map((x, dx) => (
                                                            <div className="col">
                                                              <ExampleCarouselImage
                                                                text1={
                                                                  isJsonString(
                                                                    x
                                                                  )
                                                                    ? JSON.parse(
                                                                        x
                                                                      )[0]
                                                                    : ""
                                                                }
                                                                text2={
                                                                  isJsonString(
                                                                    x
                                                                  )
                                                                    ? JSON.parse(
                                                                        x
                                                                      )[1]
                                                                    : ""
                                                                }
                                                                text3={
                                                                  isJsonString(
                                                                    x
                                                                  )
                                                                    ? JSON.parse(
                                                                        x
                                                                      )[2]
                                                                    : ""
                                                                }
                                                                onClick={() => {
                                                                  const tmpSelected =
                                                                    [
                                                                      ...selectedMultivalue,
                                                                    ];
                                                                  const idx =
                                                                    tmpSelected.findIndex(
                                                                      (x) =>
                                                                        x._id ===
                                                                        data
                                                                          .field
                                                                          ._id
                                                                    );
                                                                  if (idx < 0)
                                                                    tmpSelected.push(
                                                                      {
                                                                        _id: data
                                                                          .field
                                                                          ._id,
                                                                        selected:
                                                                          i +
                                                                          dx,
                                                                      }
                                                                    );
                                                                  else
                                                                    tmpSelected[
                                                                      idx
                                                                    ].selected =
                                                                      i + dx;
                                                                  setSelectedMultivalue(
                                                                    tmpSelected
                                                                  );
                                                                }}
                                                              />
                                                            </div>
                                                          ))}
                                                      </div>
                                                    </Carousel.Item>
                                                  );
                                                } else return null;
                                              }
                                            )}
                                          </Carousel>
                                        </div>

                                        <table>
                                          {data?.field?.multivalueFields.map(
                                            (field: any, i: number) => (
                                              <tr>
                                                <td>{field.label}</td>
                                                <td>
                                                  {isJsonString(
                                                    data.children[
                                                      selectedMultivalue
                                                        .filter(
                                                          (x) =>
                                                            x._id ===
                                                            data.field._id
                                                        )
                                                        .reduce(
                                                          (ac, a) =>
                                                            ac + a.selected,
                                                          0
                                                        ) || 0
                                                    ]
                                                  )
                                                    ? JSON.parse(
                                                        data.children[
                                                          selectedMultivalue
                                                            .filter(
                                                              (x) =>
                                                                x._id ===
                                                                data.field._id
                                                            )
                                                            .reduce(
                                                              (ac, a) =>
                                                                ac + a.selected,
                                                              0
                                                            ) || 0
                                                        ]
                                                      )[i]
                                                    : ""}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </table>
                                      </>
                                    )}

                                    {data?.field?.contentType !== "ARRAY" &&
                                      data?.field?.contentType !==
                                        "OBJECTIVE" && (
                                        <FormControl
                                          disabled
                                          value={data.content}
                                          defaultValue={" "}
                                        />
                                      )}
                                  </div>
                                </InputGroup>
                              ))}
                          </>
                        )}
                      </div>
                      <div className="col">
                        <h4
                          style={{
                            textAlign: "center",
                            marginTop: "30px",
                            paddingLeft: "20px",
                            padding: "5px",
                            borderRadius: "15px",
                            color: "white",
                            background: "#dd0000",
                          }}
                        >
                          Campos Editáveis
                        </h4>
                        <div
                          className="row"
                          style={{
                            marginTop: "auto",
                            marginBottom: "auto",
                            textAlign: "center",
                          }}
                        >
                          <div
                            className="col"
                            style={{
                              marginTop: "auto",
                              marginBottom: "auto",
                              textAlign: "center",
                            }}
                          >
                            <div className="row">
                              <div className="col"> Total </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                {
                                  process.phase?.dataFields?.filter(
                                    (field: any) =>
                                      field.contentType !== "ANSWER" &&
                                      field.contentType !== "COUNT"
                                  ).length
                                }
                              </div>
                            </div>
                          </div>
                          <div
                            className="col"
                            style={{
                              marginTop: "auto",
                              marginBottom: "auto",
                              textAlign: "center",
                              color: "blue",
                            }}
                          >
                            <div className="row">
                              <div className="col"> Preenchidos </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                {
                                  process.phase?.dataFields
                                    ?.filter(
                                      (field: any) =>
                                        field.contentType !== "ANSWER" &&
                                        field.contentType !== "COUNT"
                                    )
                                    .filter((field: any) => {
                                      const content =
                                        actualPhaseData.phaseContent?.find(
                                          (x: any) => {
                                            return x?.field?._id === field._id;
                                          }
                                        )?.content;
                                      console.log(content);
                                      if (
                                        content &&
                                        content.length > 0 &&
                                        content !== "{}"
                                      )
                                        return 1;
                                      return 0;
                                    }).length
                                }
                              </div>
                            </div>
                          </div>
                          <div
                            className="col"
                            style={{
                              marginTop: "auto",
                              marginBottom: "auto",
                              textAlign: "center",
                              color: "red",
                            }}
                          >
                            <div className="row">
                              <div className="col"> Faltantes </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                {
                                  process.phase?.dataFields
                                    ?.filter(
                                      (field: any) =>
                                        field.contentType !== "ANSWER" &&
                                        field.contentType !== "COUNT"
                                    )
                                    .filter((field: any) => {
                                      const content =
                                        actualPhaseData.phaseContent?.find(
                                          (x: any) => {
                                            return x?.field?._id === field._id;
                                          }
                                        )?.content;
                                      if (
                                        content &&
                                        content.length > 0 &&
                                        content != "{}"
                                      )
                                        return 0;
                                      return 1;
                                    }).length
                                }
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <Button
                              style={{ float: "right" }}
                              hidden={!showFilledFields}
                              onClick={() => setShowFilledFields(false)}
                            >
                              Ocultar peenchidos
                            </Button>
                            <Button
                              style={{ float: "right" }}
                              hidden={showFilledFields}
                              onClick={() => setShowFilledFields(true)}
                            >
                              Exibir peenchidos
                            </Button>
                          </div>
                        </div>

                        <Form
                          onSubmit={(e) => saveContent(e)}
                          style={{ position: "relative" }}
                        >
                          {!loadingSave && (
                            <>
                              {process.phase?.dataFields
                                ?.filter(
                                  (field: any) =>
                                    field.contentType !== "ANSWER" &&
                                    field.contentType !== "COUNT"
                                )
                                .filter((field: any) => {
                                  if (showFilledFields) return 1;
                                  const content =
                                    actualPhaseData.phaseContent?.find(
                                      (x: any) => {
                                        return x?.field?._id === field._id;
                                      }
                                    )?.content;

                                  if (content && content.length > 0) return 0;
                                  return 1;
                                })
                                .map((field: any, i) => (
                                  <InputGroup key={i} className="col-lg">
                                    <div className="flex fdcolumn flex1 margin-bottom">
                                      <Form.Label>
                                        {field.name} <Attention />
                                      </Form.Label>
                                      {field.contentType === "KEYELEMENT" && (
                                        <Select
                                          getOptionLabel={(option: any) =>
                                            option.name
                                          }
                                          getOptionValue={(option: any) =>
                                            option._id
                                          }
                                          placeholder="Escolha uma opção"
                                          onChange={(e: any) => {
                                            dispatch(
                                              setPhaseContentField({
                                                fieldId: field._id,
                                                value: e._id,
                                              })
                                            );
                                          }}
                                          cacheOptions
                                          defaultOptions={
                                            keyElementArray.find(
                                              (x) =>
                                                x._id === field.keyElement?._id
                                            )?.content
                                          }
                                          defaultValue={() =>
                                            keyElementArray
                                              .find(
                                                (x) =>
                                                  x._id === field.keyElement?._id
                                              )
                                              ?.content?.find(
                                                (x: any) =>
                                                  x._id ===
                                                  actualPhaseData.phaseContent?.find(
                                                    (x) =>
                                                      x?.field?._id ===
                                                      field._id
                                                  )?.content
                                              )
                                          }
                                        />
                                      )}

                                      {field.contentType === "OBJECTIVE" && (
                                        <Select
                                          getOptionLabel={(option: any) =>
                                            option.name
                                          }
                                          getOptionValue={(option: any) =>
                                            option._id
                                          }
                                          placeholder="Escolha uma opção"
                                          onChange={(e: any) => {
                                            dispatch(
                                              setPhaseContentField({
                                                fieldId: field._id,
                                                value: e._id,
                                              })
                                            );
                                          }}
                                          cacheOptions
                                          defaultOptions={field.options}
                                          value={field.options.find(
                                            (x: any) =>
                                              x._id ===
                                              actualPhaseData.phaseContent?.find(
                                                (x: any) => {
                                                  return (
                                                    x?.field?._id === field._id
                                                  );
                                                }
                                              )?.content
                                          )}
                                        />
                                      )}
                                      {field.contentType === "CURRENCY" && (
                                        <NumericFormat
                                          thousandSeparator="."
                                          decimalSeparator=","
                                          className="form-control"
                                          prefix="R$ "
                                          allowNegative={false}
                                          value={
                                            actualPhaseData.phaseContent?.find(
                                              (x) => {
                                                return (
                                                  x?.field?._id === field._id
                                                );
                                              }
                                            )?.content
                                          }
                                          onValueChange={(e) => {
                                            dispatch(
                                              setPhaseContentField({
                                                fieldId: field._id,
                                                value: e.floatValue,
                                              })
                                            );
                                          }}
                                        />
                                      )}
                                      {(field.contentType === "TEXT" ||
                                        field.contentType === "STRING") && (
                                        <>
                                          <FormControl
                                            as={
                                              field.contentType === "TEXT"
                                                ? "textarea"
                                                : "input"
                                            }
                                            value={
                                              actualPhaseData.phaseContent?.find(
                                                (x) => {
                                                  return (
                                                    x?.field?._id === field._id
                                                  );
                                                }
                                              )?.content
                                            }
                                            placeholder={field.description}
                                            onChange={(e) => {
                                              dispatch(
                                                setPhaseContentField({
                                                  fieldId: field._id,
                                                  value: e.target.value,
                                                })
                                              );
                                            }}
                                          />

                                          {field.contentType === "TEXT" &&
                                            field.preText?.length > 0 && (
                                              <div
                                                style={{
                                                  paddingTop: 5,
                                                  paddingLeft: 20,
                                                  paddingRight: 20,
                                                  fontSize: "0.85em",
                                                  textAlign: "justify",
                                                }}
                                              >
                                                <div
                                                  style={{ fontWeight: "bold" }}
                                                >
                                                  Pré-texto:
                                                </div>
                                                <div>{field.preText}</div>
                                              </div>
                                            )}
                                        </>
                                      )}
                                      {field.contentType === "ARRAY" && (
                                        <>
                                          <Card
                                            style={{
                                              padding: 10,
                                              paddingBottom: 20,
                                            }}
                                          >
                                            {getMultivalueContentCount(field) >
                                              0 && (
                                              <span
                                                style={{
                                                  textAlign: "center",
                                                  padding: "5px",
                                                  borderRadius: "15px",
                                                  color: "white",
                                                  background: "#dd1010",
                                                }}
                                              >
                                                {getMultivalueContentCount(
                                                  field
                                                )}{" "}
                                                entradas faltante(s)
                                              </span>
                                            )}
                                            {field.multivalueFields.map(
                                              (x: any, i: number) => (
                                                <div key={i}>
                                                  <Form.Label>
                                                    {x.label}
                                                    <Attention />
                                                  </Form.Label>
                                                  {x.keyElement && (
                                                    <Select
                                                      getOptionLabel={(
                                                        option: any
                                                      ) => option.name}
                                                      getOptionValue={(
                                                        option: any
                                                      ) => option._id}
                                                      placeholder="Escolha uma opção"
                                                      onChange={(e: any) => {
                                                        dispatch(
                                                          setPhaseContentFieldKey(
                                                            {
                                                              fieldId:
                                                                field._id,
                                                              value: e._id,
                                                              i,
                                                            }
                                                          )
                                                        );
                                                      }}
                                                      cacheOptions
                                                      defaultOptions={x.keyElement.keyOptions.map(
                                                        (x: any) => ({
                                                          _id: x,
                                                          name: x,
                                                        })
                                                      )}
                                                      value={field.options.find(
                                                        (x: any) =>
                                                          x._id ===
                                                          actualPhaseData.phaseContent?.find(
                                                            (x: any) => {
                                                              return (
                                                                x?.field
                                                                  ?._id ===
                                                                field._id
                                                              );
                                                            }
                                                          )?.content
                                                      )}
                                                    />
                                                  )}
                                                  {!x.keyElement && (
                                                    <FormControl
                                                      as={"input"}
                                                      placeholder={""}
                                                      value={
                                                        actualPhaseData?.phaseContent?.find(
                                                          (x) => {
                                                            return (
                                                              x?.field?._id ===
                                                              field._id
                                                            );
                                                          }
                                                        )?.content
                                                          ? (JSON.parse(
                                                              actualPhaseData?.phaseContent?.find(
                                                                (x) => {
                                                                  return (
                                                                    x?.field
                                                                      ?._id ===
                                                                    field._id
                                                                  );
                                                                }
                                                              )
                                                                ?.content as string
                                                            )[i] as string)
                                                          : ""
                                                      }
                                                      onChange={(e) => {
                                                        dispatch(
                                                          setPhaseContentFieldKey(
                                                            {
                                                              fieldId:
                                                                field._id,
                                                              value:
                                                                e.target.value,
                                                              i,
                                                            }
                                                          )
                                                        );
                                                      }}
                                                      style={{
                                                        marginBottom: 10,
                                                      }}
                                                    />
                                                  )}
                                                </div>
                                              )
                                            )}
                                            <div className="row">
                                              <div className="w100 inline-flex justify-content-end margin-top-1">
                                                <Button
                                                  style={{
                                                    marginTop: 20,
                                                    float: "right",
                                                  }}
                                                  onClick={() => {
                                                    dispatch(
                                                      addContentToChildren(
                                                        field._id
                                                      )
                                                    );
                                                  }}
                                                >
                                                  Adicionar
                                                </Button>
                                              </div>
                                            </div>
                                            <table>
                                              <tr>
                                                <th>
                                                  <div className="col">no.</div>
                                                </th>
                                                {field.multivalueFields.map(
                                                  (x: any, i: number) => (
                                                    <th
                                                      key={i}
                                                      style={{
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      {x.label}
                                                    </th>
                                                  )
                                                )}
                                                <td>&nbsp;</td>
                                              </tr>
                                              {actualPhaseData?.phaseContent
                                                ?.find((x) => {
                                                  return (
                                                    x?.field?._id === field._id
                                                  );
                                                })
                                                ?.children?.map(
                                                  (x: any, j: any) => (
                                                    <tr key={j}>
                                                      <td
                                                        className="col"
                                                        style={{
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        {j + 1}
                                                      </td>
                                                      {Object.keys(
                                                        JSON.parse(x)
                                                      ).map((y, i) => (
                                                        <td
                                                          key={i}
                                                          className="col"
                                                        >
                                                          {JSON.parse(x)[y]}{" "}
                                                          &nbsp;
                                                        </td>
                                                      ))}
                                                      <td
                                                        className="col"
                                                        style={{
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        <span
                                                          onClick={() => {
                                                            dispatch(
                                                              removeContentChild(
                                                                {
                                                                  fieldId:
                                                                    field._id,
                                                                  ind: j,
                                                                }
                                                              )
                                                            );
                                                          }}
                                                          className="inline-flex align-center jcc bg-danger icon"
                                                          style={{
                                                            width: "2em",
                                                            height: "2em",
                                                            borderRadius: "7px",
                                                          }}
                                                          title="Gerenciar"
                                                        >
                                                          <FaTrashAlt color="white" />
                                                        </span>
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                            </table>
                                          </Card>
                                        </>
                                      )}
                                    </div>
                                  </InputGroup>
                                ))}
                              <div className="w100 inline-flex jcsb mt-3">
                                <Button
                                  variant="secondary"
                                  className="flex align-center jcc"
                                  onClick={() => navigate(-1)}
                                >
                                  <RiArrowGoBackLine
                                    style={{ marginRight: 10 }}
                                  />{" "}
                                  Voltar
                                </Button>
                                <div className="w100 inline-flex justify-content-end margin-top-1">
                                  <Button
                                    variant="success"
                                    type="submit"
                                    disabled={loadingImage === true}
                                    className="flex align-center jcc"
                                  >
                                    <SaveRounded style={{ marginRight: 10 }} />{" "}
                                    Salvar
                                  </Button>
                                </div>
                              </div>
                            </>
                          )}
                        </Form>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
