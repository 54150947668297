import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { handleThunkRejection } from "../utils";

export const saveFieldStoreFactory = (
  typePrefix: string,
  apiFunction: any,
  slice: any,
  field: any,
) => {
  
  return createAsyncThunk(
    typePrefix,
    async (_, { getState, rejectWithValue }) => {
      const state: any = getState();
      const { token } = state.auth;
      try {
        return await apiFunction(token, state[slice][field]);
      } catch (e: any) {
        console.log(`Error save::createAsyncThunk ${e.status} ${e.message}`);

        return handleThunkRejection(e, rejectWithValue);
      }
    }
  );
};

export const addSaveFieldStoreFactoryReducer = (
  builder: ActionReducerMapBuilder<any>,
  saveFieldStoreFactory: any
) => {
  builder
    .addCase(saveFieldStoreFactory.pending, (state, _action) => {
      return {
        ...state,
        screen: {
          ...state.screen,
          loadingSave: true,
        },
      };
    })
    .addCase(saveFieldStoreFactory.fulfilled, (state, action) => {
      var all = [...state.list.all];
      all.push(action.payload.data);
      return {
        ...state,
        screen: {
          ...state.screen,
          loadingSave: false,
        },
        list: { ...state.list, all: all },
      };
    })
    .addCase(saveFieldStoreFactory.rejected, (state: any, action: any) => {
      console.log(`${action.payload?.code} - ${action.payload?.message}`);
      return {
        ...state,
        screen: {
          ...state.screen,
          errorMessages: action.payload?.message,
          loadingSave: false,
        },
      };
    });
};
