import { API_URI } from "../../constants/consts";
import { api } from "./configs/axiosConfigs";

const API_PATH = API_URI + "/processes";

export const ProcessAPI = {

  uploadFile: async function (
    token: string,
    data: any,
    schema: any,
    cancel = false
  ) {
    var formData = new FormData();
    formData.append("step_id", data);
    formData.append("properties", JSON.stringify(schema.properties))
    return api
      .request({
        url: `${API_PATH}/upload-media/${schema.id}`,
        method: "POST",
        data: formData,
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
  
  assignProcess: async function (token: string, data: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/assign`,
        method: "POST",
        data: { process: data },
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
  advanceProcess: async function (token: string, data: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/advance`,
        method: "POST",
        data: { process: data },
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getAllAssigned: async function (
    token = "",
    page: any,
    search: any,
    cancel = false
  ) {
    return api
      .request({
        url: `${API_PATH}/assigned-to-user`,
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getAllOpened: async function (
    token = "",
    page: any,
    search: any,
    cancel = false
  ) {
    return api
      .request({
        url: `${API_PATH}/opened-to-user`,
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getAll: async function (token = "", page: any, search: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}?page=${page}&search=${search ?? ""}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getPreview: async function (token = "", id: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/${id}/preview`,
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getById: async function (token = "", id: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/${id}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  save: async function (token: string, data: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}`,
        method: "POST",
        data: { ...data, client: data.client._id, product: data.product._id },
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
  update: async function (token: string, data: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/${data._id}`,
        method: "PUT",
        data: { ...data, phase: data.phase._id },
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  delete: async function (token = "", id: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/${id}`,
        method: "DELETE",
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
};
