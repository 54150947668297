// src/apis/productAPI.js

import { API_URI } from "../../constants/consts";
import { api } from "./configs/axiosConfigs";

const API_PATH = API_URI + "/fields";

export const FieldAPI = {
  getCoverFields: async function (token = "", page: any, search: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/cover-fields`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getAll: async function (token = "", page: any, search: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}?page=${page}&search=${search ?? ""}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  getById: async function (token = "", id: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/${id}`,
        method: "GET",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  save: async function (token: string, data: any, cancel = false) {
    console.log(data);
    return api
      .request({
        url: `${API_PATH}`,
        method: "POST",
        data: {
          ...data,
          phase: data.phase?._id,
          countField: data.countField?._id,
          multivalueFields: data.multivalueFields.map((x: any) => ({
            ...x,
            keyElement: x.keyElement._id,
          })),
          keyElement:
            data.keyElement?._id && data.keyElement?._id !== ""
              ? data.keyElement._id
              : null,
        },
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data;
        return payload;
      });
  },
  update: async function (token: string, data: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/${data._id}`,
        method: "PUT",
        data: {
          ...data,
          phase: data.phase?._id,
          countField: data.countField?._id,
          multivalueFields: data.multivalueFields.map((x: any) => ({
            ...x,
            keyElement: x.keyElement?._id,
          })),
          keyElement:
            data.keyElement?._id && data.keyElement?._id !== ""
              ? data.keyElement._id
              : null,
        },
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
  delete: async function (token = "", id: any, cancel = false) {
    return api
      .request({
        url: `${API_PATH}/${id}`,
        method: "DELETE",
        headers: {
          Authorization: token,
        },
        // signal: cancel
        //   ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        //   : undefined,
      })
      .then((response) => {
        const payload = response.data.data;
        return payload;
      });
  },
};

// defining the cancel API object for ProductAPI
// const cancelApiObject = defineCancelApiObject(QuestionsAPI);
