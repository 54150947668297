import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { handleThunkRejection } from "../utils";

export const fetchIdStoreFactory = (
  typePrefix: string,
  apiFunction: any,
  slice: any
) => {
  return createAsyncThunk(
    typePrefix,
    async (params: any, { getState, rejectWithValue }) => {
      const state: any = getState();
      const { token } = state.auth;
      try {
        return await apiFunction(
          token,
          params.id ?? state[slice].list.selectedId,
          false
        );
      } catch (e: any) {
        console.log(
          `Error fetchList::createAsyncThunk ${e.status} ${e.message}`
        );
        return handleThunkRejection(e, rejectWithValue);
      }
    }
  );
};

export const addFetchIdStoreFactoryReducer = (
  builder: ActionReducerMapBuilder<any>,
  fetchIdStoreFactory: any,
  fulfilledFunction?: any
) => {
  builder
    .addCase(fetchIdStoreFactory.pending, (state, _action) => {
      state.screen.loadingScreen = true;
      state.screen.errorMessages = null;
    })
    .addCase(fetchIdStoreFactory.fulfilled, (state, action) => {
      let data = { ...action.payload };
      if (!action.payload.annotations) {
        data.annotations = [];
      }
      state.model = data;
      state.screen.loadingScreen = false;
      state.screen.errorMessages = null;
      if (fulfilledFunction !== undefined) fulfilledFunction(state);
    })
    .addCase(fetchIdStoreFactory.rejected, (state, action) => {
      return {
        ...state,
        list: {
          ...state.list,
          all: [],
        },
        screen: {
          ...state.screen,
          errorCode: action.error.code,
          errorMessages: [action.error.message],
          loadingScreen: false,
        },
      };
    });
};
