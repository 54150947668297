import "../../assets/css/app.scss";
// import Header from "../../component/views/Header";
import { Outlet } from "react-router-dom";
// import SideBar from "../../component/views/SideBar/old__index";
import SideBar from "../../component/views/SideBar";

export function HomeView() {
  return (
    <div
      className={`app`}
      style={{
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <SideBar />
      <div
        className="content-page"
        style={{
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <div
          className="content"
          style={{
            transition: "all 0.3s ",
            background: "#f8f8fa",
          }}
        >
          <div className="container-fluid">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
