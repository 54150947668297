import {
  listState,
  paginationState,
  screenState,
} from "../../../constants/consts";
import { Jurisprudence } from "../../../model/Jurisprudence";

export const modelInitialState = {
  pagination: paginationState,
  screen: screenState,
  list: listState,
  model: Jurisprudence,
};
