import {
  listState,
  paginationState,
  screenState,
} from "../../../constants/consts";
import { Phase } from "../../../model/Phase";
import { Process } from "../../../model/Process";

export const modelInitialState = {
  pagination: paginationState,
  screen: screenState,
  list: listState,
  model: Process,
  assigned: new Array(),
  opened: new Array(),
  assignId: "",
  advanceId: "",
  //
  actualPhase: Phase,
  actualPhaseData: { phase: Phase, phaseContent: new Array() },
  pastPhaseData: new Array(),
  keyElementArray: new Array(),
};
