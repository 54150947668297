import { SaveRounded } from "@material-ui/icons";
import { useState } from "react";
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../services/stores/hooks/useAppDispatch";
import { importFields } from "../../services/stores/PhaseStore/slice";
import { setFieldsJSON } from "../../services/stores/PhaseStore/actions";
import { RiArrowGoBackLine } from "react-icons/ri";

export function ImportFieldForm(props: { editMode?: boolean }) {
  const [text, setText] = useState("");
  const [totalFields, setTotalFields] = useState(0);
  const [message, setMessage] = useState("");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const isJson = (str: string) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const parseText = (str: string) => {
    setText(str);
    setTotalFields(0);
    setMessage("");
    if (!isJson(str)) {
      setTotalFields(0);
      setMessage("Texto não está em formato JSON.");
      return;
    }
    const data = JSON.parse(str);
    if (!data.fields) return;
    let dataCheckPassed = true;
    data.fields.forEach((element: any) => {
      if (!element.slug || !element.value) dataCheckPassed = false;
    });
    if (!dataCheckPassed) {
      setMessage("Campos não estão no formato adequado.");
      return false;
    }
    setMessage(`Foram encontrados ${data.fields.length} campos de formulário.`);
    setTotalFields(data.fields.length);
  };
  return (
    <div className="row">
      <div className={"col-12"}>
        <div className={"pt-5 px-3"}>
          <div className={"card-body"} id="card"></div>
          <div>
            <h4
              className="mt-0 header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              Importação de Campos
            </h4>
            <p>Importe campos de formulários de clientes para as fases</p>
            <br />
          </div>
          <div>
            <InputGroup className="col-lg">
              <div className="flex fdcolumn flex1 margin-bottom">
                <Form.Label>Campos em formato JSON</Form.Label>
                <FormControl
                  required
                  as={"textarea"}
                  rows={10}
                  placeholder="Texto Completo"
                  value={text}
                  onChange={(e) => parseText(e.target.value)}
                  // required
                  // title="Coloque o nome completo do usuário"
                />
              </div>
            </InputGroup>
          </div>
          {text.length > 0 && <div>{message}</div>}
          <div className="w100 inline-flex jcsb mt-3">
            {/* <DefaultButton
                        bg="secondary"
                        text="Voltar"
                        icon={<RiArrowGoBackLine />}
                        to="/users-admin"
                      /> */}
            <Button
              variant="secondary"
              className="flex align-center jcc"
              onClick={() => navigate(-1)}
            >
              <RiArrowGoBackLine style={{ marginRight: 10 }} /> Voltar
            </Button>
            <div className="w100 inline-flex justify-content-end margin-top-1">
              <Button
                variant="success"
                type="submit"
                disabled={
                  text.length === 0 || !isJson(text) || totalFields === 0
                }
                className="flex align-center jcc"
                onClick={() => {
                  dispatch(setFieldsJSON({ _id: params.id, fields: text }));
                  dispatch(importFields()).then((result) => {
                    if (result.type.includes("fulfilled")) navigate(-1);
                  });
                }}
              >
                <SaveRounded style={{ marginRight: 10 }} /> Importar
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
