export default function getAdminDashboard({
  from,
  to,
  dispatch,
  addWidget,
  fetchId,
}) {
  // dispatch(addWidget({id: "",}));
  // dispatch(fetchId({force: true, id: "",from,to,}));
}
