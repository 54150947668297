import {
  listState,
  paginationState,
  screenState,
} from "../../../constants/consts";
import { Document } from "../../../model/Document";

export const modelInitialState = {
  pagination: paginationState,
  screen: screenState,
  list: listState,
  model: Document,
};
