import {
  BrowserRouter,
  Outlet,
  RouterProvider,
  Routes,
  createBrowserRouter,
} from "react-router-dom";
import { useAppSelector } from "../services/stores/hooks/useAppSelector";
import AppStack from "./AppStack";
import AuthStack from "./AuthStack";
import ErrorPage from "../component/views/ErrorPage";
import Blank from "../screens/Blank";
import EditProfile from "../screens/EditProfile";
import { UserView } from "../screens/UsersView/UserView";
import { UserForm } from "../screens/UsersView/UserForm";

import { DashboardView } from "../screens/DashboardView/list";
import { authSlice } from "../services/stores/auth/slice";
import { FieldForm } from "../screens/FieldView/FieldForm";
import { FieldList } from "../screens/FieldView/FieldList";
import { PhaseForm } from "../screens/PhaseView/PhaseForm";
import { PhaseList } from "../screens/PhaseView/PhaseList";
import { ProcessForm } from "../screens/ProcessView/ProcessForm";
import { ProcessList } from "../screens/ProcessView/ProcessList";
import { GroupList } from "../screens/ProductView/ProductList";
import { GroupForm } from "../screens/ProductView/ProductForm";
import { ClientForm } from "../screens/ClientView/ClientForm";
import { ClientList } from "../screens/ClientView/ClientList";
import { LawyerProcessForm } from "../screens/LawyerProcessView/LawyerProcessForm";
import { LawyerProcessList } from "../screens/LawyerProcessView/LawyerProcessList";
import { LicensedForm } from "../screens/LicensedView/LicensedForm";
import { LicensedList } from "../screens/LicensedView/LicensedList";
import { ImportFieldForm } from "../screens/PhaseView/ImporFieldForm";
import { JurisprudenceList } from "../screens/JurisprudenceView/JurisprudenceList";
import { JurisprudenceForm } from "../screens/JurisprudenceView/JurisprudenceForm";
import { MatterList } from "../screens/MatterView/MatterList";
import { MatterForm } from "../screens/MatterView/MatterForm";
import { KeyElementList } from "../screens/KeyElementView/KeyElementList";
import { KeyElementForm } from "../screens/KeyElementView/KeyElementForm";
import { DocumentList } from "../screens/DocumentView/DocumentList";
import { DocumentForm } from "../screens/DocumentView/DocumentForm";

export const { logout } = authSlice.actions;

export default function AppNav() {
  const { logged, blank } = useAppSelector((store) => store.auth);
  function Root() {
    return blank === true ? (
      <>
        <Outlet />
      </>
    ) : logged === undefined ? (
      <></>
    ) : logged === true ? (
      <AppStack />
    ) : (
      <AuthStack />
    );
  }

  const router = createBrowserRouter([
    {
      path: "*",
      element: <Root />,
      errorElement: <ErrorPage />,
      children:
        blank === false
          ? [
              {
                path: "",
                element: <DashboardView />,
              },
              {
                path: "blank",
                element: <Blank />,
              },
              {
                path: "edit-profile",
                element: <EditProfile />,
              },
              // USERS VIEW -----------------------------------------
              {
                path: "users",
                element: <UserView />,
              },
              {
                path: "users/create",
                element: <UserForm />,
              },
              {
                path: "users/edit/:id",
                element: <UserForm editMode />,
              },
              // CLIENTS VIEW -----------------------------------------
              {
                path: "clients",
                element: <ClientList />,
              },
              {
                path: "clients/create",
                element: <ClientForm />,
              },
              {
                path: "clients/:id",
                element: <ClientForm editMode />,
              },
              // LICENSEDS VIEW -----------------------------------------
              {
                path: "licenseds",
                element: <LicensedList />,
              },
              {
                path: "licenseds/create",
                element: <LicensedForm />,
              },
              {
                path: "licenseds/:id",
                element: <LicensedForm editMode />,
              },
              // MATTERS VIEW -----------------------------------------
              {
                path: "matters",
                element: <MatterList />,
              },
              {
                path: "matters/create",
                element: <MatterForm />,
              },
              {
                path: "matters/:id",
                element: <MatterForm editMode />,
              },
              // DOCUMENTS VIEW -----------------------------------------
              {
                path: "documents",
                element: <DocumentList />,
              },
              {
                path: "documents/create",
                element: <DocumentForm />,
              },
              {
                path: "documents/:id",
                element: <DocumentForm editMode />,
              },
              // ELEMENT KEY VIEW -----------------------------------------
              {
                path: "key-elements",
                element: <KeyElementList />,
              },
              {
                path: "key-elements/create",
                element: <KeyElementForm />,
              },
              {
                path: "key-elements/:id",
                element: <KeyElementForm editMode />,
              },
              // JURISPRUDENCES VIEW -----------------------------------------
              {
                path: "jurisprudences",
                element: <JurisprudenceList />,
              },
              {
                path: "jurisprudences/create",
                element: <JurisprudenceForm />,
              },
              {
                path: "jurisprudences/:id",
                element: <JurisprudenceForm editMode />,
              },
              // FIELDS VIEW -----------------------------------------
              {
                path: "fields",
                element: <FieldList />,
              },
              {
                path: "fields/create",
                element: <FieldForm />,
              },
              {
                path: "fields/:id",
                element: <FieldForm editMode />,
              },
              // PRODUCTS VIEW -----------------------------------------
              {
                path: "products",
                element: <GroupList />,
              },
              {
                path: "products/create",
                element: <GroupForm />,
              },
              {
                path: "products/:id",
                element: <GroupForm editMode />,
              },
              // PHASES VIEW -----------------------------------------
              {
                path: "phases",
                element: <PhaseList />,
              },
              {
                path: "phases/create",
                element: <PhaseForm />,
              },
              {
                path: "phases/:id",
                element: <PhaseForm editMode />,
              },
              {
                path: "phases/:id/import-fields",
                element: <ImportFieldForm />,
              },
              // PROCESSES VIEW -----------------------------------------
              {
                path: "processes",
                element: <ProcessList />,
              },
              {
                path: "processes/create",
                element: <ProcessForm />,
              },
              {
                path: "processes/:id",
                element: <ProcessForm editMode />,
              },
              // LAWYER PROCESS VIEW -----------------------------------------
              {
                path: "lawyer-processes",
                element: <LawyerProcessList />,
              },
              {
                path: "lawyer-processes/create",
                element: <LawyerProcessForm />,
              },
              {
                path: "lawyer-processes/:id",
                element: <LawyerProcessForm editMode />,
              },
            ]
          : [],
    },
  ]);

  return (
    <div>
      <BrowserRouter>
        <Routes></Routes>
      </BrowserRouter>
      <RouterProvider router={router} />
    </div>
  );
}
