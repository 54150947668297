import {
  listState,
  paginationState,
  screenState,
} from "../../../constants/consts";
import { Field } from "../../../model/Field";

export const modelInitialState = {
  pagination: paginationState,
  screen: screenState,
  list: listState,
  model: Field,
  fields: [],
};
