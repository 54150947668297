import { Matter } from "./Matter";

export const Jurisprudence = {
    _id: "",
    name: "",
    matter: Matter,
    keyElements: new Array(),
    procedence: "",
    text: "",
    conditions: "",
};