import { useEffect, useState } from "react";

// unidades
import { useAppSelector } from "../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../services/stores/hooks/useAppDispatch";
import {
  Button,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import { Attention } from "../../component/base/Attention";
import { useNavigate, useParams } from "react-router-dom";
import {
  setName,
  setDescription,
  setPhase,
  setCountField,
  setErrorMessage,
  clearWindowState,
  setPreText,
  setKey,
  setContentType,
  addOptions,
  removeOption,
  addMultivalueField,
  removeMultivalueField,
  setKeyElement,
} from "../../services/stores/FieldStore/actions";
import { RiArrowGoBackLine } from "react-icons/ri";
import {
  fetchIdThunk,
  fetchListFieldStoreThunk,
  saveThunk,
  updateThunk,
} from "../../services/stores/FieldStore/slice";
import { SaveRounded } from "@material-ui/icons";
import AsyncSelect from "react-select/async";
import { fetchListThunk as fetchPhaseListThunk } from "../../services/stores/PhaseStore/slice";
import { fetchListThunk as fetchFieldListThunk } from "../../services/stores/FieldStore/slice";
import Select from "react-select/async";
import { FaTrashAlt } from "react-icons/fa";
import { fetchListThunk as fetchKeyElementsListThunk } from "../../services/stores/KeyElementStore/slice";
var debounce = require("lodash.debounce");

export function FieldForm(props: { editMode?: boolean }) {
  interface FieldTypeOption {
    readonly value: string;
    readonly label: string;
  }

  const yesNoTypes: readonly FieldTypeOption[] = [
    { label: "Sim", value: "1" },
    { label: "Não", value: "0" },
  ];

  const fieldContentTypes: readonly FieldTypeOption[] = [
    { label: "Resposta simples", value: "STRING" },
    { label: "Elemento-chave", value: "KEYELEMENT" },
    { label: "Campo de contagem", value: "COUNT" },
    { label: "Resposta Moeda", value: "CURRENCY" },
    { label: "Resposta de Formulário", value: "ANSWER" },
    { label: "Multivalorado", value: "ARRAY" },
    { label: "Texto", value: "TEXT" },
    { label: "Alternativas", value: "OBJECTIVE" },
  ];

  const [optionName, setOptionName] = useState("");
  const [optionText, setOptionText] = useState("");

  const [fieldLabel, setFieldLabel] = useState("");
  const [fieldKey, setFieldKey] = useState("");
  const [multiVariableKeyElement, setMultiVariableKeyElement] = useState({
    _id: null,
  });
  const [multiIsRepeated, setMultiIsRepeated] = useState("");

  const {
    errorMessages,
    loadingSave,
    loadingScreen,
    successMessage,
    loadingImage,
  } = useAppSelector((store) => store.fieldSlice.screen);
  const {
    name,
    description,
    preText,
    key,
    contentType,
    options,
    multivalueFields,
    phase,
    keyElement,
  } = useAppSelector((store) => store.fieldSlice.model);

  const { fields } = useAppSelector((store) => store.fieldSlice);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const saveContent = (e: any) => {
    e.preventDefault();
    if (props.editMode) {
      dispatch(updateThunk()).then((result) => {
        if (result.type.includes("fulfilled")) navigate(-1);
      });
    } else {
      dispatch(saveThunk()).then((result) => {
        if (result.type.includes("fulfilled")) navigate(-1);
      });
    }
  };

  const selectedPhase = useAppSelector((store) => store.fieldSlice.model.phase);

  const searchPhase = (query: any, callback: any) => {
    dispatch(fetchPhaseListThunk({ force: true, search: query })).then(
      (result) => {
        callback(result.payload);
      }
    );
  };

  const selectedCountField = useAppSelector(
    (store) => store.fieldSlice.model.countField
  );

  const [selectedKeyElement, setSelectedKeyElement] = useState({ _id: null });
  const searchKeyElement = (query: any, callback: any) => {
    dispatch(fetchKeyElementsListThunk({ force: true, search: query })).then(
      (result) => {
        callback(result.payload);
      }
    );
  };

  const searchCountField = (query: any, callback: any) => {
    dispatch(fetchListFieldStoreThunk({ force: true, search: query })).then(
      (result) => {
        console.log(result.payload.result);
        console.log(phase);
        const ret = result.payload.result
          .filter(
            (x: any) =>
              x.contentType === "COUNT" &&
              x.phase.product._id === phase?.product?._id
          )
          .map((x: any) => {
            return { name: x.name, _id: x._id };
          });
        callback(ret);
      }
    );
  };

  useEffect(() => {
    if (props.editMode) {
      dispatch(fetchIdThunk({ id: params.id, force: true }));
    }

    return () => {
      dispatch(clearWindowState());
    };
  }, []);
  return (
    <div className="row">
      <div className="col-12">
        <div className="py-5 px-3">
          <div className="card-body" id="card" style={{ overflow: "visible" }}>
            <h4
              className="mt-0 header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              {props.editMode ? "Editar" : "Cadastrar"} Campo
            </h4>
            <p style={{ fontSize: "1rem", color: "black" }}>
              {props.editMode
                ? "Edite um campo do seu sistema"
                : "Cadastre os campos do seu sistema"}
            </p>
            <br />

            {loadingScreen == false && (
              <div>
                {successMessage && successMessage != "" && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-success alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(clearWindowState())}
                      ></button>

                      <p style={{ marginBottom: 0, whiteSpace: "pre" }}>
                        {successMessage}
                      </p>
                    </div>
                    <br />
                  </div>
                )}
                {errorMessages && errorMessages.length > 0 && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(setErrorMessage(""))}
                      ></button>
                      {errorMessages.map((error, i) => (
                        <p key={i} style={{ marginBottom: 0 }}>
                          {error}
                        </p>
                      ))}
                    </div>
                    <br />
                  </div>
                )}
                <Form
                  onSubmit={(e) => saveContent(e)}
                  style={{ position: "relative" }}
                >
                  <div className="row">
                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Nome <Attention />
                        </Form.Label>
                        <FormControl
                          placeholder="Nome"
                          value={name}
                          onChange={(e) => dispatch(setName(e.target.value))}
                          // required
                          // title="Coloque o nome completo do usuário"
                        />
                      </div>
                    </InputGroup>

                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Chave do Campo (Coverforce) <Attention />
                        </Form.Label>
                        <FormControl
                          placeholder="Chave de integração do campo"
                          value={key}
                          onChange={(e) => dispatch(setKey(e.target.value))}
                          // required
                          // title="Coloque o nome completo do usuário"
                        />
                      </div>
                    </InputGroup>
                  </div>
                  <div className="row">
                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Descrição <Attention />
                        </Form.Label>
                        <FormControl
                          placeholder="Descrição"
                          value={description}
                          onChange={(e) =>
                            dispatch(setDescription(e.target.value))
                          }
                          // required
                          // title="Coloque o nome completo do usuário"
                        />
                      </div>
                    </InputGroup>
                    {/* 
                    <Card style={{ marginBottom: "1em", paddingTop:"0.5em" }}>
                       <div className="row">
                        <InputGroup>
                          <div className="flex fdcolumn flex1 margin-bottom">
                            <Form.Label>
                              Tipo <Attention />
                            </Form.Label>
                            <Select
                              getOptionLabel={(option: any) => option.label}
                              getOptionValue={(option: any) => option.value}
                              placeholder="Escolha um tipo de campo"
                              onChange={(e: any) => {
                                dispatch(setType(e.value));
                              }}
                              cacheOptions
                              defaultOptions={fieldTypes}
                              value={fieldTypes.find((x) => x.value == type)}
                            />
                          </div>
                        </InputGroup>
                      </div> 
                    </Card> 
                    */}
                    <div className="row">
                      <InputGroup className="col-lg">
                        <div className="flex fdcolumn flex1 margin-bottom">
                          <Form.Label>
                            Fase <Attention />
                          </Form.Label>
                          <AsyncSelect
                            getOptionLabel={(option: any) =>
                              option.product?.name + " - " + option.name
                            }
                            getOptionValue={(option: any) => option._id}
                            placeholder="Digite parte do nome da fase..."
                            onChange={(e: any) => {
                              dispatch(setPhase(e));
                            }}
                            cacheOptions
                            value={selectedPhase}
                            loadOptions={debounce(searchPhase, 250)}
                          />
                        </div>
                      </InputGroup>
                    </div>
                    <Card style={{ marginBottom: "1em", paddingTop: "0.5em" }}>
                      <div className="row">
                        <InputGroup className="col-lg">
                          <div className="flex fdcolumn flex1 margin-bottom">
                            <Form.Label>
                              Tipo de Conteúdo <Attention />
                            </Form.Label>
                            <Select
                              getOptionLabel={(option: any) => option.label}
                              getOptionValue={(option: any) => option.value}
                              placeholder="Escolha um tipo de conteúdo para o campo"
                              onChange={(e: any) => {
                                dispatch(setContentType(e.value));
                              }}
                              cacheOptions
                              defaultOptions={fieldContentTypes}
                              value={fieldContentTypes.find(
                                (x) => x.value == contentType
                              )}
                            />
                          </div>
                        </InputGroup>
                      </div>
                      {contentType === "ARRAY" && (
                        <div>
                          <div className="row">
                            <InputGroup className="col-lg">
                              <div className="flex fdcolumn flex1 margin-bottom">
                                <Form.Label>
                                  Campo de contagem <Attention />
                                </Form.Label>
                                <AsyncSelect
                                  getOptionLabel={(option: any) => option.name}
                                  getOptionValue={(option: any) => option._id}
                                  placeholder="Digite parte do nome do campo..."
                                  onChange={(e: any) => {
                                    dispatch(setCountField(e));
                                  }}
                                  cacheOptions
                                  value={selectedCountField}
                                  loadOptions={debounce(searchCountField, 250)}
                                />
                              </div>
                            </InputGroup>
                          </div>
                          <Row>
                            <Col lg={4}>
                              <InputGroup>
                                <div className="flex fdcolumn flex1 margin-bottom">
                                  <Form.Label>
                                    Rótulo do campo <Attention />
                                  </Form.Label>
                                  <FormControl
                                    placeholder="Escreva o rótulo do campo"
                                    value={fieldLabel}
                                    onChange={(e) =>
                                      setFieldLabel(e.target.value)
                                    }
                                    // required
                                    // title="Coloque o nome completo do usuário"
                                  />
                                </div>
                              </InputGroup>
                            </Col>
                            <Col lg={8}>
                              <InputGroup className="col col-lg">
                                <div className="flex fdcolumn flex1 margin-bottom">
                                  <Form.Label>
                                    Chave do campo <Attention />
                                  </Form.Label>
                                  <FormControl
                                    placeholder="Escreva a chave do campo"
                                    value={fieldKey}
                                    onChange={(e) =>
                                      setFieldKey(e.target.value)
                                    }
                                    // required
                                    // title="Coloque o nome completo do usuário"
                                  />
                                </div>
                              </InputGroup>
                            </Col>
                          </Row>
                          <div className="row">
                            <div className="col">
                              <InputGroup className="col-lg">
                                <div className="flex fdcolumn flex1 margin-bottom">
                                  <Form.Label>Elemento-Chave</Form.Label>
                                  <AsyncSelect
                                    getOptionLabel={(option: any) =>
                                      option.name
                                    }
                                    getOptionValue={(option: any) => option}
                                    placeholder="Escolha o elemento-chave (se aplicável)"
                                    onChange={(e: any) => {
                                      setMultiVariableKeyElement(e);
                                    }}
                                    cacheOptions
                                    value={multiVariableKeyElement}
                                    loadOptions={debounce(
                                      searchKeyElement,
                                      250
                                    )}
                                  />
                                </div>
                              </InputGroup>
                            </div>
                            {multiVariableKeyElement?._id && (
                              <div className="col">
                                <InputGroup className="col-lg">
                                  <div className="flex fdcolumn flex1 margin-bottom">
                                    <Form.Label>
                                      Replica documentos? <Attention />
                                    </Form.Label>
                                    <Select
                                      getOptionLabel={(option: any) =>
                                        option.label
                                      }
                                      getOptionValue={(option: any) =>
                                        option.value
                                      }
                                      placeholder="O campo replica documentos?"
                                      onChange={(e: any) => {
                                        setMultiIsRepeated(e.value);
                                      }}
                                      cacheOptions
                                      defaultOptions={yesNoTypes}
                                      value={yesNoTypes.find(
                                        (x) => x.value == multiIsRepeated
                                      )}
                                    />
                                  </div>
                                </InputGroup>
                              </div>
                            )}
                          </div>

                          <div className="flex flex1 margin-bottom">
                            <Button
                              onClick={() =>
                                dispatch(
                                  addMultivalueField({
                                    label: fieldLabel,
                                    key: fieldKey,
                                    isRepeated: multiIsRepeated,
                                    keyElement: multiVariableKeyElement,
                                  })
                                )
                              }
                            >
                              Adicionar Campo
                            </Button>
                          </div>

                          {multivalueFields.map((x: any, key: any) => (
                            <div key={key} className="row">
                              <Row>
                                <Col style={{ paddingBottom: "0.5em" }}>
                                  <div>
                                    {x.key} - {x.label}
                                    <br />
                                    {x.keyElement?.name !== undefined && (
                                      <small>
                                        {x.keyElement
                                          ? "Elemento-chave:" +
                                            x.keyElement.name
                                          : ""}
                                        &nbsp;
                                        {x.keyElement && x.isRepeated == "1"
                                          ? " (replicar)"
                                          : ""}
                                      </small>
                                    )}
                                  </div>
                                </Col>
                                <Col style={{ paddingBottom: "0.5em" }}>
                                  <span
                                    onClick={() => {
                                      dispatch(removeMultivalueField(x));
                                    }}
                                    className="inline-flex align-center jcc bg-danger icon"
                                    style={{
                                      marginLeft: "1em",
                                      width: "2em",
                                      height: "2em",
                                      borderRadius: "7px",
                                    }}
                                    title="Gerenciar"
                                  >
                                    <FaTrashAlt color="white" />
                                  </span>
                                </Col>
                              </Row>
                            </div>
                          ))}
                        </div>
                      )}
                      {contentType === "OBJECTIVE" && (
                        <div>
                          <Row>
                            <Col lg={4}>
                              <InputGroup>
                                <div className="flex fdcolumn flex1 margin-bottom">
                                  <Form.Label>
                                    Nome da Opção <Attention />
                                  </Form.Label>
                                  <FormControl
                                    placeholder="Escreva o nome da opção"
                                    value={optionName}
                                    onChange={(e) =>
                                      setOptionName(e.target.value)
                                    }
                                    // required
                                    // title="Coloque o nome completo do usuário"
                                  />
                                </div>
                              </InputGroup>
                            </Col>
                            <Col lg={8}>
                              <InputGroup className="col col-lg">
                                <div className="flex fdcolumn flex1 margin-bottom">
                                  <Form.Label>
                                    Texto Fixo <Attention />
                                  </Form.Label>
                                  <FormControl
                                    as="textarea"
                                    rows={2}
                                    placeholder="Escreva o texto a ser fixado"
                                    value={optionText}
                                    onChange={(e) =>
                                      setOptionText(e.target.value)
                                    }
                                    // required
                                    // title="Coloque o nome completo do usuário"
                                  />
                                </div>
                              </InputGroup>
                            </Col>
                          </Row>

                          <div className="flex flex1 margin-bottom">
                            <Button
                              onClick={() =>
                                dispatch(
                                  addOptions({
                                    name: optionName,
                                    text: optionText,
                                  })
                                )
                              }
                            >
                              Adicionar Opção
                            </Button>
                          </div>
                          {options.map((x: any, key: any) => (
                            <div key={key} className="row">
                              <div className="col">
                                <div>
                                  {x.name} -{" "}
                                  {x.text.length < 20
                                    ? x.text
                                    : x.text.substring(19) + "..."}
                                </div>
                              </div>
                              <div className="col">
                                <span
                                  onClick={() => {
                                    dispatch(removeOption(x));
                                  }}
                                  className="inline-flex align-center jcc bg-danger icon"
                                  style={{
                                    width: "2em",
                                    height: "2em",
                                    borderRadius: "7px",
                                  }}
                                  title="Gerenciar"
                                >
                                  <FaTrashAlt color="white" />
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </Card>

                    {contentType === "TEXT" && (
                      <div className="row">
                        <InputGroup className="col-lg">
                          <div className="flex fdcolumn flex1 margin-bottom">
                            <Form.Label>Pré-Texto</Form.Label>
                            <FormControl
                              as="textarea"
                              rows={5}
                              placeholder="Digite o pré-texto (caso se aplique)"
                              value={preText}
                              onChange={(e) =>
                                dispatch(setPreText(e.target.value))
                              }
                              // required
                              // title="Coloque o nome completo do usuário"
                            />
                          </div>
                        </InputGroup>
                      </div>
                    )}
                    {(contentType === "ANSWER" || contentType === "KEYELEMENT") && (
                      <>
                        <div className="row">
                          <InputGroup className="col-lg">
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Elemento-chave <Attention />
                              </Form.Label>
                              <AsyncSelect
                                getOptionLabel={(option: any) => option.name}
                                getOptionValue={(option: any) => option}
                                placeholder="Digite parte do nome da fase..."
                                onChange={(e: any) => {
                                  dispatch(setKeyElement(e));
                                }}
                                cacheOptions
                                value={keyElement}
                                loadOptions={debounce(searchKeyElement, 250)}
                              />
                            </div>
                          </InputGroup>
                        </div>
                      </>
                    )}
                  </div>
                  {!loadingSave && (
                    <div className="w100 inline-flex jcsb mt-3">
                      {/* <DefaultButton
                        bg="secondary"
                        text="Voltar"
                        icon={<RiArrowGoBackLine />}
                        to="/users-admin"
                      /> */}
                      <Button
                        variant="secondary"
                        className="flex align-center jcc"
                        onClick={() => navigate("/users-admin")}
                      >
                        <RiArrowGoBackLine style={{ marginRight: 10 }} /> Voltar
                      </Button>
                      <div className="w100 inline-flex justify-content-end margin-top-1">
                        {/* <DefaultButton
                          type="submit"
                          bg="confirm"
                          text="Salvar"
                          disabled={loadingImage == true}
                          loadingtext="Salvando"
                          loading={loadingSave}
                        /> */}
                        <Button
                          variant="success"
                          type="submit"
                          disabled={loadingImage == true}
                          className="flex align-center jcc"
                        >
                          <SaveRounded style={{ marginRight: 10 }} /> Salvar
                        </Button>
                      </div>
                    </div>
                  )}

                  {loadingSave == true && (
                    <div className="row">
                      {" "}
                      <div className="col-12 d-flex justify-content-center align-items-center margin-top-1">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        ></div>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            )}

            {loadingScreen && (
              <div className="row">
                {" "}
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
