import * as React from "react";

export interface ExampleCarouselImageProps {
  title?: string | undefined;
  text1: string;
  text2?: string;
  text3?: string;
  text4?: string;
  onClick: any 
}

const ExampleCarouselImage: React.FC<ExampleCarouselImageProps> = ({
  title = "Placeholder",
  text1,
  text2,
  text3,
  text4,
  onClick
}) => (
  <svg
    className="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
    width="200"
    height="100"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    preserveAspectRatio="xMidYMid slice"
    focusable="false"
    onClick={onClick}
  >
    <title>{title}</title>
    <rect width="200" height="100" fill="#555" />
    <text
      x="10"
      y="20"
      fill="#FFF"
      dy=".3em"
    >
      {text1}
    </text>
    <text
      x="10"
      y="40"
      fill="#FFF"
      dy=".3em"
    >
      {text2}
    </text>
    <text
      x="10"
      y="60"
      fill="#FFF"
      dy=".3em"
    >
      {text3}
    </text>
    <text
      x="10"
      y="60"
      fill="#FFF"
      dy=".3em"
    >
      {text4}
    </text>
  </svg>
);

ExampleCarouselImage.displayName = "ExampleCarouselImage";

export default ExampleCarouselImage;
