import { modelSlice } from "./slice";

export const {
  setSearch,
  setWarning,
  setSelectedId,
  clearWindowState,
  setLoading,
  setName,
  setErrorMessage,
  setPage,
  setModel,
  setKey,
  addMatter,
  removeMatter,
} = modelSlice.actions;
