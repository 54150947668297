import { useEffect, useState } from "react";

// unidades
import { useAppSelector } from "../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../services/stores/hooks/useAppDispatch";
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";
import { Attention } from "../../component/base/Attention";
import DefaultButton from "../../component/base/DefaultButton";
import { useNavigate, useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import {
  setName,
  setEmail,
  setPassword,
  setConfirmPassword,
  setErrorMessage,
  clearWindowState,
  addPhase,
  removePhase,
  setRole,
} from "../../services/stores/userAdminStore/actions";
import PasswordInput from "../../component/base/PasswordInput";
import { UserRole } from "../../constants/enum/UserRole";
import { RiArrowGoBackLine } from "react-icons/ri";
import {
  fetchIdThunk,
  saveThunk,
  updateThunk,
} from "../../services/stores/userAdminStore/slice";
import { SaveRounded } from "@material-ui/icons";
import { fetchListThunk as fetchPhaseListThunk } from "../../services/stores/PhaseStore/slice";
import { FaTrashAlt } from "react-icons/fa";
import Select from "react-select/async";
var debounce = require("lodash.debounce");

export function UserForm(props: { editMode?: boolean }) {
  const {
    errorMessages,
    loadingSave,
    loadingScreen,
    successMessage,
    loadingImage,
  } = useAppSelector((store) => store.userAdmin.screen);
  const { name, email, password, password_confirmation, phases, role } =
    useAppSelector((store) => store.userAdmin.model);

  const [selectedParagraph, setSelectedParagraph] = useState(null);
  const searchParagraph = (query: any, callback: any) => {
    dispatch(fetchPhaseListThunk({ force: true, search: query })).then(
      (result) => {
        callback(result.payload);
      }
    );
  };
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const params = useParams();

  interface UserRoleOption {
    readonly value: string;
    readonly label: string;
  }

  const userRoles: readonly UserRoleOption[] = [
    { label: "Administrador", value: UserRole.ADMIN_USER },
    { label: "Advogado", value: UserRole.LAWYER_USER },
    { label: "Externo", value: UserRole.EXTERNAL },
  ];
  const saveContent = (e: any) => {
    e.preventDefault();
    if (props.editMode) {
      dispatch(updateThunk()).then((result) => {
        if (result.type.includes("fulfilled")) history("/users");
      });
    } else {
      dispatch(saveThunk()).then((result) => {
        if (result.type.includes("fulfilled")) history("/users");
      });
    }
  };

  useEffect(() => {
    if (props.editMode) {
      dispatch(fetchIdThunk({ id: params.id, force: true }));
    }

    return () => {
      dispatch(clearWindowState());
    };
  }, []);
  return (
    <div className="row">
      <div className="col-12">
        <div className="py-5 px-3">
          <div className="card-body" id="card" style={{ overflow: "visible" }}>
            <h4
              className="mt-0 header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              {props.editMode ? "Editar" : "Cadastrar"} Usuário
            </h4>
            <p style={{ fontSize: "1rem", color: "black" }}>
              {props.editMode
                ? "Edite um usuário do seu sistema"
                : "Cadastre os usuários do seu sistema"}
            </p>
            <br />

            {loadingScreen == false && (
              <div>
                {successMessage && successMessage != "" && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-success alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(clearWindowState())}
                      ></button>

                      <p style={{ marginBottom: 0, whiteSpace: "pre" }}>
                        {successMessage}
                      </p>
                    </div>
                    <br />
                  </div>
                )}
                {errorMessages && errorMessages.length > 0 && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(setErrorMessage(""))}
                      ></button>
                      {errorMessages.map((error, i) => (
                        <p key={i} style={{ marginBottom: 0 }}>
                          {error}
                        </p>
                      ))}
                    </div>
                    <br />
                  </div>
                )}
                <Form
                  onSubmit={(e) => saveContent(e)}
                  style={{ position: "relative" }}
                >
                  <div className="row">
                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Nome <Attention />
                        </Form.Label>
                        <FormControl
                          placeholder="Nome"
                          value={name}
                          onChange={(e) => dispatch(setName(e.target.value))}
                          // required
                          // title="Coloque o nome completo do usuário"
                        />
                      </div>
                    </InputGroup>

                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          E-mail <Attention />
                        </Form.Label>
                        <FormControl
                          placeholder="E-mail"
                          value={email}
                          onChange={(e) => dispatch(setEmail(e.target.value))}
                          // required
                          // title="Coloque o nome completo do usuário"
                        />
                      </div>
                    </InputGroup>
                  </div>
                  <div className="row">
                    <InputGroup className="col-lg">
                      <PasswordInput
                        required={!props.editMode}
                        label="Senha"
                        placeholder="Digite sua senha"
                        className="flex1"
                        value={password}
                        OnChangeText={(e: any) =>
                          dispatch(setPassword(e.target.value))
                        }
                        // title="Coloque a senha do usuário"
                      />
                    </InputGroup>

                    <InputGroup className="col-lg">
                      <PasswordInput
                        required={!props.editMode}
                        label="Confirmação de senha"
                        placeholder="Digite a confirmação de senha"
                        className="flex1"
                        value={password_confirmation}
                        OnChangeText={(e: any) =>
                          dispatch(setConfirmPassword(e.target.value))
                        }

                        // title="Coloque a senha do usuário"
                      />
                    </InputGroup>
                  </div>

                  <div className="row">
                    <div className="col">
                      <InputGroup className="col-lg">
                        <div className="flex fdcolumn flex1 margin-bottom">
                          <Form.Label>
                            Fases <Attention />
                          </Form.Label>
                          <AsyncSelect
                            getOptionLabel={(option: any) =>
                              option.product.name + " - " + option.name
                            }
                            getOptionValue={(option: any) => option._id}
                            placeholder="Digite parte do nome da fase..."
                            onChange={(e: any) => {
                              setSelectedParagraph(e);
                            }}
                            cacheOptions
                            value={selectedParagraph}
                            loadOptions={debounce(searchParagraph, 250)}
                          />
                        </div>
                      </InputGroup>
                    </div>
                  </div>
                  <div className="flex flex1 margin-bottom">
                    <Button
                      onClick={() => dispatch(addPhase(selectedParagraph))}
                    >
                      Adicionar
                    </Button>
                  </div>
                  {phases.map((x) => (
                    <div className="row">
                      <div className="col">
                        <div>
                          {x.product.name} - {x.name}
                        </div>
                      </div>
                      <div className="col">
                        <span
                          onClick={() => {
                            dispatch(removePhase(x));
                          }}
                          className="inline-flex align-center jcc bg-danger icon"
                          style={{
                            width: "2em",
                            height: "2em",
                            borderRadius: "7px",
                          }}
                          title="Gerenciar"
                        >
                          <FaTrashAlt color="white" />
                        </span>
                      </div>
                    </div>
                  ))}
                  <div className="row">
                    <div className="col">
                      <InputGroup className="col-lg">
                        <div className="flex fdcolumn flex1 margin-bottom">
                          <Form.Label>
                            Perfil <Attention />
                          </Form.Label>
                          <Select
                            getOptionLabel={(option: any) => option.label}
                            getOptionValue={(option: any) => option.value}
                            placeholder="Digite parte do nome da fase..."
                            onChange={(e: any) => {
                              dispatch(setRole(e.value));
                            }}
                            cacheOptions
                            defaultOptions={userRoles}
                            value={userRoles.find((x) => x.value == role)}
                          />
                        </div>
                      </InputGroup>
                    </div>
                  </div>
                  {!loadingSave && (
                    <div className="w100 inline-flex jcsb mt-3">
                      {/* <DefaultButton
                        bg="secondary"
                        text="Voltar"
                        icon={<RiArrowGoBackLine />}
                        to="/users"
                      /> */}
                      <Button
                        variant="secondary"
                        className="flex align-center jcc"
                        onClick={() => history("/users")}
                      >
                        <RiArrowGoBackLine style={{ marginRight: 10 }} /> Voltar
                      </Button>
                      <div className="w100 inline-flex justify-content-end margin-top-1">
                        {/* <DefaultButton
                          type="submit"
                          bg="confirm"
                          text="Salvar"
                          disabled={loadingImage == true}
                          loadingtext="Salvando"
                          loading={loadingSave}
                        /> */}
                        <Button
                          variant="success"
                          type="submit"
                          disabled={loadingImage == true}
                          className="flex align-center jcc"
                        >
                          <SaveRounded style={{ marginRight: 10 }} /> Salvar
                        </Button>
                      </div>
                    </div>
                  )}

                  {loadingSave == true && (
                    <div className="row">
                      {" "}
                      <div className="col-12 d-flex justify-content-center align-items-center margin-top-1">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        ></div>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            )}

            {loadingScreen && (
              <div className="row">
                {" "}
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
