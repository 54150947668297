import { useEffect, useState } from "react";

// unidades
import { useAppSelector } from "../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../services/stores/hooks/useAppDispatch";
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import { Attention } from "../../component/base/Attention";
import { useNavigate, useParams } from "react-router-dom";
import {
  setName,
  setDescription,
  setErrorMessage,
  clearWindowState,
  setProduct,
  setOrder,
  setType,
  setText,
  setContentType,
  setKey,
  setHasPreview,
  removeText,
  addText,
  addDocument,
  removeDocument,
} from "../../services/stores/PhaseStore/actions";
import { RiArrowGoBackLine } from "react-icons/ri";
import {
  fetchCoverPhasesThunk,
  fetchIdThunk,
  saveThunk,
  updateThunk,
} from "../../services/stores/PhaseStore/slice";
import { SaveRounded } from "@material-ui/icons";

import AsyncSelect from "react-select/async";
import Select from "react-select/async";
import { fetchListThunk as fetchProductListThunk } from "../../services/stores/ProductStore/slice";
import { fetchListThunk as fetchDocumentListThunk } from "../../services/stores/DocumentStore/slice";
import { SelectOption } from "../../constants/consts";
import { Checkbox } from "@material-ui/core";
import { FaTrashAlt } from "react-icons/fa";
var debounce = require("lodash.debounce");

export function PhaseForm(props: { editMode?: boolean }) {
  const [fieldLabel, setFieldLabel] = useState("");
  const [fieldKey, setFieldKey] = useState("");
  const [fieldText, setFieldText] = useState("");

  const phaseTypes: readonly SelectOption[] = [
    { label: "Executor (Interna)", value: "INTERNAL" },
    { label: "Externa (Integração)", value: "EXTERNAL" },
    { label: "Final", value: "FINAL" },
  ];

  const phaseContentTypes: readonly SelectOption[] = [
    { label: "Texto Completo", value: "TEXT" },
    { label: "Dados do processo", value: "DATA" },
  ];

  const {
    errorMessages,
    loadingSave,
    loadingScreen,
    successMessage,
    loadingImage,
  } = useAppSelector((store) => store.phaseSlice.screen);
  const {
    name,
    description,
    order,
    type,
    contentType,
    text,
    key,
    hasPreview,
    documents,
  } = useAppSelector((store) => store.phaseSlice.model);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [selectedDocument, setSelectedDocument] = useState(null);
  const searchDocument = (query: any, callback: any) => {
    dispatch(fetchDocumentListThunk({ force: true, search: query })).then(
      (result) => {
        callback(result.payload);
      }
    );
  };

  const selectedProduct = useAppSelector(
    (store) => store.phaseSlice.model.product
  );
  const searchProduct = (query: any, callback: any) => {
    dispatch(fetchProductListThunk({ force: true, search: query })).then(
      (result) => {
        callback(result.payload);
      }
    );
  };

  const [selectedPhase, setSelectedPhase] = useState(null);
  const searchPhase = (query: any, callback: any) => {
    dispatch(fetchCoverPhasesThunk({ force: true, search: query })).then(
      (result) => {
        callback(result.payload.result);
      }
    );
  };

  const saveContent = (e: any) => {
    e.preventDefault();
    if (props.editMode) {
      dispatch(updateThunk()).then((result) => {
        if (result.type.includes("fulfilled")) navigate(-1);
      });
    } else {
      dispatch(saveThunk()).then((result) => {
        if (result.type.includes("fulfilled")) navigate(-1);
      });
    }
  };

  useEffect(() => {
    if (props.editMode) {
      dispatch(fetchIdThunk({ id: params.id, force: true })).then((result) => {
        if (result.type.includes("fulfilled"))
          dispatch(fetchCoverPhasesThunk({ force: true })).then((result2) => {
            if (result2.type.includes("fulfilled")) {
              const item = result2.payload.result.find(
                (x: any) => x._id == result.payload.key
              );
              setSelectedPhase(item);
            }
          });
      });
    }

    return () => {
      dispatch(clearWindowState());
    };
  }, []);
  return (
    <div className="row">
      <div className="col-12">
        <div className="py-5 px-3">
          <div className="card-body" id="card" style={{ overflow: "visible" }}>
            <h4
              className="mt-0 header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              {props.editMode ? "Editar" : "Cadastrar"} fase
            </h4>
            <p style={{ fontSize: "1rem", color: "black" }}>
              {props.editMode
                ? "Edite uma fase do seu sistema"
                : "Cadastre as fases do seu sistema"}
            </p>
            <br />

            {loadingScreen == false && (
              <div>
                {successMessage && successMessage != "" && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-success alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(clearWindowState())}
                      ></button>

                      <p style={{ marginBottom: 0, whiteSpace: "pre" }}>
                        {successMessage}
                      </p>
                    </div>
                    <br />
                  </div>
                )}
                {errorMessages && errorMessages.length > 0 && (
                  <div
                    style={{ display: "flex", marginBottom: 15, width: "100%" }}
                  >
                    <div
                      className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => dispatch(setErrorMessage(""))}
                      ></button>
                      {errorMessages.map((error, i) => (
                        <p key={i} style={{ marginBottom: 0 }}>
                          {error}
                        </p>
                      ))}
                    </div>
                    <br />
                  </div>
                )}
                <Form
                  onSubmit={(e) => saveContent(e)}
                  style={{ position: "relative" }}
                >
                  <div className="row">
                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Nome <Attention />
                        </Form.Label>
                        <FormControl
                          required
                          placeholder="Nome"
                          value={name}
                          onChange={(e) => dispatch(setName(e.target.value))}
                          // required
                          // title="Coloque o nome completo do usuário"
                        />
                      </div>
                    </InputGroup>
                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Ordem <Attention />
                        </Form.Label>
                        <FormControl
                          required
                          type="number"
                          placeholder="Ordem"
                          value={order}
                          onChange={(e) => dispatch(setOrder(e.target.value))}
                          // required
                          // title="Coloque o nome completo do usuário"
                        />
                      </div>
                    </InputGroup>
                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Chave da Fase (Coverforce) <Attention />
                        </Form.Label>
                        <AsyncSelect
                          getOptionLabel={(option: any) => option.name}
                          getOptionValue={(option: any) => option._id}
                          placeholder="Digite parte do nome do produto..."
                          onChange={(e: any) => {
                            setSelectedPhase(e);
                            dispatch(setKey(e._id));
                          }}
                          cacheOptions
                          value={selectedPhase}
                          loadOptions={debounce(searchPhase, 250)}
                        />
                      </div>
                    </InputGroup>
                  </div>
                  <div className="row">
                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Descrição <Attention />
                        </Form.Label>
                        <FormControl
                          placeholder="Descrição"
                          value={description}
                          onChange={(e) =>
                            dispatch(setDescription(e.target.value))
                          }
                          // required
                          // title="Coloque o nome completo do usuário"
                        />
                      </div>
                    </InputGroup>
                  </div>
                  <div className="row">
                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Tipo <Attention />
                        </Form.Label>
                        <Select
                          getOptionLabel={(option: any) => option.label}
                          getOptionValue={(option: any) => option.value}
                          placeholder="Escolha um tipo de fase"
                          onChange={(e: any) => {
                            dispatch(setType(e.value));
                          }}
                          cacheOptions
                          defaultOptions={phaseTypes}
                          value={phaseTypes.find((x) => x.value == type)}
                        />
                      </div>
                    </InputGroup>
                  </div>
                  <div className="row">
                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Tipo de Conteúdo
                          <Attention />
                        </Form.Label>
                        <Select
                          getOptionLabel={(option: any) => option.label}
                          getOptionValue={(option: any) => option.value}
                          placeholder="Escolha um tipo de conteúdo para a fase"
                          onChange={(e: any) => {
                            dispatch(setContentType(e.value));
                          }}
                          cacheOptions
                          defaultOptions={phaseContentTypes}
                          value={phaseContentTypes.find(
                            (x) => x.value == contentType
                          )}
                        />
                      </div>
                    </InputGroup>
                  </div>
                  <div className="row">
                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Check
                          type={"checkbox"}
                          label={"Possui preview"}
                          checked={hasPreview == "1" ? true : false}
                          onChange={() => {
                            dispatch(
                              setHasPreview(hasPreview == "1" ? "0" : "1")
                            );
                          }}
                        />
                      </div>
                    </InputGroup>
                  </div>
                  <div className="row">
                    <InputGroup className="col-lg">
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Produto <Attention />
                        </Form.Label>
                        <AsyncSelect
                          required
                          getOptionLabel={(option: any) => option.name}
                          getOptionValue={(option: any) => option._id}
                          placeholder="Digite parte do nome do campo..."
                          onChange={(e: any) => {
                            dispatch(setProduct(e));
                          }}
                          cacheOptions
                          value={selectedProduct}
                          loadOptions={debounce(searchProduct, 250)}
                        />
                      </div>
                    </InputGroup>
                  </div>
                  {/* 
                  {contentType === "TEXT" && (
                    <div>
                      <Row>
                        <Col lg={4}>
                          <InputGroup>
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Filtro de Pergunta <Attention />
                              </Form.Label>
                              <FormControl
                                placeholder="Escreva a chave da pergunta"
                                value={fieldLabel}
                                onChange={(e) => setFieldLabel(e.target.value)}
                                // required
                                // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>
                        </Col>
                        <Col lg={8}>
                          <InputGroup className="col col-lg">
                            <div className="flex fdcolumn flex1 margin-bottom">
                              <Form.Label>
                                Resposta <Attention />
                              </Form.Label>
                              <FormControl
                                placeholder="Escreva a resposta do campo"
                                value={fieldKey}
                                onChange={(e) => setFieldKey(e.target.value)}
                                // required
                                // title="Coloque o nome completo do usuário"
                              />
                            </div>
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}></Col>
                        <InputGroup className="col-lg">
                          <div className="flex fdcolumn flex1 margin-bottom">
                            <Form.Label>
                              Texto Completo <Attention />
                            </Form.Label>
                            <FormControl
                              required
                              as={"textarea"}
                              rows={10}
                              placeholder="Texto Completo"
                              value={fieldText}
                              onChange={(e) => setFieldText(e.target.value)}
                              // required
                              // title="Coloque o nome completo do usuário"
                            />
                          </div>
                        </InputGroup>
                      </Row>
                      <div className="flex flex1 margin-bottom">
                        <Button
                          onClick={() =>
                            dispatch(
                              addText({
                                label: fieldLabel,
                                key: fieldKey,
                                text: fieldText,
                              })
                            )
                          }
                        >
                          Adicionar Campo
                        </Button>
                      </div>
                      {text?.map((x: any, key: any) => (
                        <div key={key} className="row">
                          <Row>
                            <Col style={{ paddingBottom: "0.5em" }}>
                              {x.key} - {x.label} - {x.text}
                              <span
                                onClick={() => {
                                  dispatch(removeText(x));
                                }}
                                className="inline-flex align-center jcc bg-danger icon"
                                style={{
                                  marginLeft: "1em",
                                  width: "2em",
                                  height: "2em",
                                  borderRadius: "7px",
                                }}
                                title="Gerenciar"
                              >
                                <FaTrashAlt color="white" />
                              </span>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </div>

                    // <div className="row">
                    //   <InputGroup className="col-lg">
                    //     <div className="flex fdcolumn flex1 margin-bottom">
                    //       <Form.Label>
                    //         Texto Completo <Attention />
                    //       </Form.Label>
                    //       <FormControl
                    //         required
                    //         as={"textarea"}
                    //         rows={10}
                    //         placeholder="Texto Completo"
                    //         value={text}
                    //         onChange={(e) => dispatch(setText(e.target.value))}
                    //         // required
                    //         // title="Coloque o nome completo do usuário"
                    //       />
                    //     </div>
                    //   </InputGroup>
                    // </div>
                  )} 
                   */}
                  <div className="row">
                    <div className="col">
                      <InputGroup className="col-lg">
                        <div className="flex fdcolumn flex1 margin-bottom">
                          <Form.Label>
                            Documentos <Attention />
                          </Form.Label>
                          <AsyncSelect
                            getOptionLabel={(option: any) => option.name}
                            getOptionValue={(option: any) => option._id}
                            placeholder="Digite parte do nome do documento..."
                            onChange={(e: any) => {
                              setSelectedDocument(e);
                            }}
                            cacheOptions
                            value={selectedDocument}
                            loadOptions={debounce(searchDocument, 250)}
                          />
                        </div>
                      </InputGroup>
                    </div>
                  </div>
                  <div className="flex flex1 margin-bottom">
                    <Button
                      onClick={() => dispatch(addDocument(selectedDocument))}
                    >
                      Adicionar
                    </Button>
                  </div>
                  {documents?.map((x) => (
                    <div className="row">
                      <div className="col">
                        <div>{x.name}</div>
                      </div>
                      <div className="col">
                        <span
                          onClick={() => {
                            dispatch(removeDocument(x));
                          }}
                          className="inline-flex align-center jcc bg-danger icon"
                          style={{
                            width: "2em",
                            height: "2em",
                            borderRadius: "7px",
                          }}
                          title="Gerenciar"
                        >
                          <FaTrashAlt color="white" />
                        </span>
                      </div>
                    </div>
                  ))}
                  {!loadingSave && (
                    <div className="w100 inline-flex jcsb mt-3">
                      {/* <DefaultButton
                        bg="secondary"
                        text="Voltar"
                        icon={<RiArrowGoBackLine />}
                        to="/users-admin"
                      /> */}
                      <Button
                        variant="secondary"
                        className="flex align-center jcc"
                        onClick={() => navigate("/users-admin")}
                      >
                        <RiArrowGoBackLine style={{ marginRight: 10 }} /> Voltar
                      </Button>
                      <div className="w100 inline-flex justify-content-end margin-top-1">
                        {/* <DefaultButton
                          type="submit"
                          bg="confirm"
                          text="Salvar"
                          disabled={loadingImage == true}
                          loadingtext="Salvando"
                          loading={loadingSave}
                        /> */}
                        <Button
                          variant="success"
                          type="submit"
                          disabled={loadingImage == true}
                          className="flex align-center jcc"
                        >
                          <SaveRounded style={{ marginRight: 10 }} /> Salvar
                        </Button>
                      </div>
                    </div>
                  )}

                  {loadingSave == true && (
                    <div className="row">
                      {" "}
                      <div className="col-12 d-flex justify-content-center align-items-center margin-top-1">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        ></div>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            )}

            {loadingScreen && (
              <div className="row">
                {" "}
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
