import {
  listState,
  paginationState,
  screenState,
} from "../../../constants/consts";
import { Product } from "../../../model/Product";

export const modelInitialState = {
  pagination: paginationState,
  screen: screenState,
  list: listState,
  model: Product,
};
