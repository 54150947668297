import { Link } from "react-router-dom";

// unidades
import { FaListAlt } from "react-icons/fa";
import { Button } from "react-bootstrap";
import Search from "../base/Search";

var pesquisarTimeout: any = null;

export function Searchbar(props: {
  search_text: any;
  onSearchButtonClick: () => void;
  button_text?: any;
  button_link?: any;
  component?: any;
  search_input_value: any;
  onChangeSearchInput: (value: any) => void;
  onClickButton?: any;
  hide_btn?: any;
}) {
  return (
    <div>
      <div className="flex jcsb flex-wrap mb-4">
        {props.search_text != undefined && (
          <Search
            inputValue={props.search_input_value}
            placeholder={props.search_text}
            ValueChange={(value: any) => {
              if (value == undefined) {
                return undefined;
              }
              props.onChangeSearchInput(value);
              clearTimeout(pesquisarTimeout);
              pesquisarTimeout = setTimeout(() => {
                props.onSearchButtonClick();
              }, 500);
            }}
          />
        )}
        <div className="d-flex justify-content-end">
          {!props.hide_btn &&
            props.button_text != undefined &&
            props.button_text && (
              <Link
                to={{
                  pathname: props.button_link,
                  // state: {
                  //     course_id: id
                  // }
                }}
                style={{ textDecoration: "none" }}
              >
                <Button variant="success" className="flex align-center jcc">
                  <FaListAlt style={{ marginRight: 10 }} />{" "}
                  <p style={{ margin: 0 }}>{props.button_text}</p>
                </Button>
              </Link>
            )}
          {props.onClickButton != undefined && props.button_text && (
            <Button
              onClick={(e: any) => {
                e.preventDefault();
                props.onClickButton();
              }}
              variant="success"
              className="flex align-center jcc"
            >
              <FaListAlt style={{ marginRight: 10 }} />{" "}
              <p style={{ margin: 0 }}>{props.button_text}</p>
            </Button>
          )}
          {props.component ? props.component : ""}
        </div>
      </div>
    </div>
  );
}
