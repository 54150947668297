import { createAsyncThunk, ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { clearErrorState, handleThunkRejection } from "../../utils";
import { UsersAPI } from "../../../apis/UsersAPI";

export const update = createAsyncThunk(
  "users-admin/update",
  async (params, { getState, rejectWithValue }) => {
    const state: any = getState();
    const { token } = state.auth;
    const { model } = state.editProfile;

    if (
      state.editProfile.model.password !=
      state.editProfile.password_confirmation
    )
      return rejectWithValue({
        code: "PASSWORD_MISMATCH",
        message: "Senha não confere.",
        data: null,
      });
    try {
      return await UsersAPI.update(token, model);
    } catch (e: any) {
      console.log(`Error update::createAsyncThunk ${e.status} ${e.message}`);
      return handleThunkRejection(e, rejectWithValue);
    }
  }
);

export const addUpdateReducers = (builder: ActionReducerMapBuilder<any>) => {
  builder
    .addCase(update.pending, (state, _action) => {
      return {
        ...state,
        error_message: "",
        success_message: "",
        loading_save: true,
      };
    })
    .addCase(update.fulfilled, (state, action) => {
      return {
        ...state,
        error_message: "",
        success_message: "Perfil alterado com sucesso.",
        loading_save: false,
      };
    })
    .addCase(update.rejected, (state, action) => {
      const error:any = action.payload;
      return {
        ...state,
        error_code: error.code,
        error_data: error.data,
        success_message: "",
        error_message: error.message,
        loading_save: false,
      };
    });
};
