import { KeyElement } from "./KeyElement";
import { Phase } from "./Phase";

export const Field = {
  _id: "",
  name: "",
  description: "",
  phase: Phase,
  type: "",
  preText: "",
  key: "",
  contentType: "",
  keyElement: KeyElement,
  contentType: "",
  countField: "",
  multivalueFields: new Array(),
  options: new Array(),
};
