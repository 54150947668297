import { useEffect } from "react";
import { useAppSelector } from "../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../services/stores/hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";

import { List } from "../../component/list/list";
import {
  setSearch,
  setSelectedId,
  setWarning,
  setPage,
} from "../../services/stores/FieldStore/actions";
import {
  deleteThunk,
  fetchListThunk,
} from "../../services/stores/FieldStore/slice";
import Swal from "sweetalert2";

export function FieldList() {
  const fields = useAppSelector((store) => store.fieldSlice.list.all);
  const { showWarning, loadingScreen, loadingSearch } = useAppSelector(
    (store) => store.fieldSlice.screen
  );

  const { pagination } = useAppSelector((store) => store.fieldSlice);
  const { search } = useAppSelector((store) => store.fieldSlice.list);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchListThunk({ force: true })).then((result) => {});
  }, []);
  return (
    <div className="row">
      {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}
      <List
        hideManager
        // searchbox ---------------------------------------------
        search_input_value={search}
        search_text="Pesquise pelo nome do campo"
        onChangeSearchInput={(value) => {
          dispatch(setSearch(value));
        }}
        onSearchButtonClick={() => {
          dispatch(setPage(1));
          dispatch(fetchListThunk({ force: true }));
        }}
        // searchbox ---------------------------------------------
        // additionalButtons={[
        //   { label: "Importar CSV", eventHandler: (e: any) => onImportClick(e) },
        // ]}
        title={"campos"}
        not_found_message={"Nenhum campo encontrado"}
        button_text="Adicionar campo"
        button_link="/fields/create"
        deleting_message={
          "Ao deletar este campo, ele não estará mais disponível no sistema"
        }
        onDeleteCancel={() => {
          dispatch(setWarning(false));
        }}
        onDeleteConfirm={() => {
          dispatch(deleteThunk()).then(() => {
            dispatch(fetchListThunk({ force: true }));
          });
          dispatch(setWarning(false));
        }}
        onDeleteActionClick={(id) => {
          dispatch(setSelectedId(id));
          dispatch(setWarning(true));
        }}
        onEditActionClick={(id) => {
          navigate("/fields/" + id);
        }}
        onChangePagination={(value: number) => {
          dispatch(setPage(value));
          dispatch(fetchListThunk({ force: true }));
        }}
        show_warning={showWarning}
        loading_screen={loadingScreen}
        loading_search={loadingSearch}
        items={fields}
        pagination={{
          last_page: pagination?.totalPages,
          current_page: pagination?.page,
        }}
        fields={[
          { name: "name", type: "text", header: "Nome" },
          { name: "phase.product.name", type: "text", header: "Produto" },
          // { name: "contentType", type: "text", header: "Conteúdo" },
          { name: "phase.name", type: "text", header: "Fase" },
          { name: "description", type: "text", header: "Descrição" },
        ]}
      ></List>
    </div>
  );
}
