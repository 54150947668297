import { useEffect, useState } from "react";
import { useAppSelector } from "../../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../../services/stores/hooks/useAppDispatch";
import { fetchId } from "../../../services/stores/dashboardStore/fetchIdThunk";
import {
  addWidget,
} from "../../../services/stores/dashboardStore/actions";
import { ResponsiveContainer } from "recharts";
import { Col, Row } from "react-bootstrap";
import { BsCalendarDate, BsQuestionCircle } from "react-icons/bs";
import Chart from "react-apexcharts";
import { CircularProgress, Tooltip } from "@material-ui/core";
import SummaryAdminSection from "./SummaryAdmin";
import TableSection from "./Table";
import { UserRole } from "../../../constants/enum/UserRole";
import getAdminDashboard from "./getAdminDashboard";

export function DashboardView() {
  const user = useAppSelector((store: any) => store.auth.me);
  const { widgets } = useAppSelector((store) => store.dashboard);
  const dispatch = useAppDispatch();
  const [from, setFrom] = useState(
    new Date()
      .toLocaleDateString("ja-JP", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      })
      .replaceAll("/", "-")
  );
  const [to, setTo] = useState("");

  useEffect(() => {
      getAdminDashboard({ from, to, dispatch, addWidget, fetchId });
  }, [from, to]);


  const handleDataFilterChange = (event: any) => {
    const value = event.target.value;
    let temp_to = new Date();

    switch (value) {
      case "1":
        temp_to = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
        break;
      case "7":
        temp_to = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
        break;
      case "30":
        temp_to = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000);
        break;
      case "90":
        temp_to = new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000);
        break;
      case "180":
        temp_to = new Date(new Date().getTime() - 180 * 24 * 60 * 60 * 1000);
        break;
      case "365":
        temp_to = new Date(new Date().getTime() - 365 * 24 * 60 * 60 * 1000);
        break;
      default:
        break;
    }

    setTo(
      temp_to
        .toLocaleDateString("ja-JP", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })
        .replaceAll("/", "-")
    );
  };

  return (
    <div>
      <div
        style={{
          // backgroundColor: "red",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          overflow: "hidden",
          padding: "2rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "1rem",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h2 style={{ fontSize: "1.8rem", fontWeight: "bold" }}>
              {new Date().getHours() >= 5 && new Date().getHours() < 12
                ? "Bom dia"
                : new Date().getHours() >= 12 && new Date().getHours() < 18
                ? "Boa tarde"
                : "Boa noite"}
              , {user?.name}!
            </h2>
            <span
              style={{
                fontSize: "1rem",
                color: "#6c757d",
              }}
            >
              Seja bem-vindo(a) ao painel Dashboard.
            </span>
          </div>

          <div
            style={{
              display: "flex",
              gap: "0.6rem",
              justifyContent: "center",
              alignItems: "center",
              color: "#6c757d",
            }}
          >
            <BsCalendarDate size={20} />
            <span>Filtrar por data</span>
            <select
              className="form-select w-auto"
              defaultValue={7}
              onChange={handleDataFilterChange}
            >
              <option value="1">24 horas</option>
              <option value="7">7 dias</option>
              <option value="30">30 dias</option>
              <option value="90">3 meses</option>
              <option value="180">6 meses</option>
              <option value="365">1 ano</option>
            </select>
            <Tooltip title="Filtrar por data">
              <BsQuestionCircle size={20} />
            </Tooltip>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            marginTop: "2rem",
          }}
        >
          {
            user?.role === UserRole.ADMIN_USER ||
            user?.role === UserRole.SUPER_USER ? (
              <SummaryAdminSection />
            ) : null
          }

          <Row
            style={{
              justifyContent: "center",
              // justifyContent: "space-between",
              // justifyContent: "space-evenly",
            }}
          >
            {widgets?.map((item: any, id: any) => {
              if (
                item.type === "table" ||
                item.type === "total" ||
                item.type === undefined ||
                item.values.length === 0
              )
                return null;
              // console.log(item.type);
              console.log(item);
              if (item.loading)
                return (
                  <div className="btn btn-light">
                    <CircularProgress style={{ marginRight: "1rem" }} />
                    Carregando...
                  </div>
                );
              const chart_data = {
                options: {
                  // colors: item.values.map((value: any) => value.fill),
                  chart: {
                    id: item.id,
                    toolbar: {
                      show: false,
                    },
                    width: "100%",
                    height: "100%",
                  },
                  dataLabels: {
                    enabled: true,
                    style: {
                      fontSize: "14px",
                      fontWeight: "bold",
                    },
                  },
                  legend: {
                    fontSize: "14px",
                    fontWeight: "bold",
                  },
                  plotOptions: {
                    bar: {
                      borderRadius: 8,
                      horizontal: item.is_horizontal,
                    },
                    radar: {
                      size: 140,
                      polygons: {
                        strokeColors: "#e9e9e9",
                        fill: {
                          colors: ["#f8f8f8", "#fff"],
                        },
                      },
                    },
                  },
                  xaxis: {
                    categories: item.values.map((value: any) => value.name),
                  },
                  labels: item.values.map((value: any) => value.name),
                },
                series:
                  item.type === "piechart" ||
                  item.type === "donut" ||
                  item.type === "polarArea"
                    ? item.values.map((value: any) => value.value)
                    : [
                        {
                          name: item.title,
                          data: item.values.map((value: any) => value.value),
                        },
                      ],
              };
              return (
                <Col
                  key={id}
                  xs={6 * item.width}
                  md={2 * item.width}
                  style={{
                    padding: "1rem",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#ececf1",
                      border: "1px solid #d0d0d6",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      borderRadius: "0.5rem",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        margin: "1rem 2rem 0 2rem",
                      }}
                    >
                      {item.title}
                    </div>
                    <ResponsiveContainer
                      width="100%"
                      aspect={item.width / item.length}
                    >
                      <Chart
                        options={chart_data.options}
                        series={chart_data.series}
                        type={item.type.replace("chart", "")}
                      />
                    </ResponsiveContainer>
                  </div>
                </Col>
              );
            })}
          </Row>

          <TableSection
            {...{
              widgets,
            }}
          />
        </div>
      </div>
    </div>
  );
}
