import { createSlice } from "@reduxjs/toolkit";

import { modelInitialState } from "./initialState";
import {
  listReducers,
  paginationReducers,
  screenReducers,
} from "../../../constants/consts";

import { ProcessAPI } from "../../apis/ProcessAPI";
import {
  addFetchListStoreFactoryReducer,
  fetchListStoreFactory,
} from "../_stores/fetchListStore";
import {
  addFetchIdStoreFactoryReducer,
  fetchIdStoreFactory,
} from "../_stores/fetchIdStore";
import {
  addDeleteStoreFactoryReducer,
  deleteStoreFactory,
} from "../_stores/deleteStore";
import {
  addSaveStoreFactoryReducer,
  saveStoreFactory,
} from "../_stores/saveStore";

import {
  addUpdateStoreFactoryReducer,
  updateStoreFactory,
} from "../_stores/updateStore";
import {
  addFetchListFieldStoreFactoryReducer,
  fetchListFieldStoreFactory,
} from "../_stores/fetchListFieldStore";
import {
  addSaveFieldStoreFactoryReducer,
  saveFieldStoreFactory,
} from "../_stores/saveFieldStore";
import {
  addUploadFileStoreFactoryReducer,
  uploadFileStoreFactory,
} from "../_stores/uploadFileStore";

export const fetchListThunk = fetchListStoreFactory(
  "process-slice/list",
  ProcessAPI.getAll,
  "processSlice"
);

export const fetchLawyerAssignedListThunk = fetchListFieldStoreFactory(
  "process-slice/list-assigned",
  ProcessAPI.getAllAssigned,
  "processSlice",
  "assigned"
);

export const fetchLawyerOpenedListThunk = fetchListFieldStoreFactory(
  "process-slice/list-opened",
  ProcessAPI.getAllOpened,
  "processSlice",
  "opened"
);

export const advanceProcess = saveFieldStoreFactory(
  "process-slice/process-advance",
  ProcessAPI.advanceProcess,
  "processSlice",
  "advanceId"
);

export const assignProcess = saveFieldStoreFactory(
  "process-slice/process-assign",
  ProcessAPI.assignProcess,
  "processSlice",
  "assignId"
);

export const fetchPreview = fetchListFieldStoreFactory(
  "process-slice/fetch-preview",
  ProcessAPI.getPreview,
  "processSlice",
  "ticket"
);

export const fetchIdThunk = fetchIdStoreFactory(
  "process-slice/get",
  ProcessAPI.getById,
  "processSlice"
);
export const deleteThunk = deleteStoreFactory(
  "process-slice/delete",
  ProcessAPI.delete,
  "processSlice"
);
export const saveThunk = saveStoreFactory(
  "process-slice/save",
  ProcessAPI.save,
  "processSlice"
);
export const updateThunk = updateStoreFactory(
  "process-slice/update",
  ProcessAPI.update,
  "processSlice"
);

export const uploadFileThunk = uploadFileStoreFactory(
  "user-admin/upload",
  ProcessAPI.uploadFile,
  "userAdmin"
);

export const modelSlice = createSlice({
  name: "process-slice",
  initialState: modelInitialState,
  reducers: {
    setContentToModel: (state) => {
      const index = state.model.data.findIndex(
        (x) => x.phase._id === state.actualPhaseData.phase._id
      );
      if (index < 0)
        state.model.data = [state.actualPhaseData, ...state.model.data];
      else state.model.data[index] = state.actualPhaseData;
    },
    setPhaseContentField: (state, action) => {
      const index = state.actualPhaseData.phaseContent.findIndex(
        (x: any) => x?.field._id === action.payload.fieldId
      );
      if (index >= 0)
        state.actualPhaseData.phaseContent[index].content =
          action.payload.value;
    },

    removeContentChild: (state, action) => {
      const index = state.actualPhaseData.phaseContent.findIndex(
        (x: any) => x?.field._id === action.payload.fieldId
      );
      state.actualPhaseData.phaseContent[index].children.splice(
        action.payload.ind,
        1
      );
    },

    addContentToChildren: (state, action) => {
      const index = state.actualPhaseData.phaseContent.findIndex(
        (x: any) => x?.field?._id === action.payload
      );
      if (!state.actualPhaseData.phaseContent[index].children)
        state.actualPhaseData.phaseContent[index].children = new Array();
      state.actualPhaseData.phaseContent[index].children.push(
        state.actualPhaseData.phaseContent[index].content
      );
      state.actualPhaseData.phaseContent[index].content = JSON.stringify({});
    },

    setPhaseContentFieldKey: (state, action) => {
      const index = state.actualPhaseData.phaseContent.findIndex(
        (x: any) => x?.field?._id === action.payload.fieldId
      );
      if (index >= 0) {
        let content = JSON.parse(
          state.actualPhaseData.phaseContent[index].content
        );
        content[action.payload.i] = action.payload.value;
        state.actualPhaseData.phaseContent[index].content =
          JSON.stringify(content);
      }
    },

    setUser: (state, action) => {
      return { ...state, model: { ...state.model, user: action.payload } };
    },
    setStage: (state, action) => {
      return { ...state, model: { ...state.model, stage: action.payload } };
    },
    setKey: (state, action) => {
      return { ...state, model: { ...state.model, key: action.payload } };
    },
    setClient: (state, action) => {
      return { ...state, model: { ...state.model, client: action.payload } };
    },
    setProduct: (state, action) => {
      return { ...state, model: { ...state.model, product: action.payload } };
    },
    setPhase: (state, action) => {
      return { ...state, model: { ...state.model, phase: action.payload } };
    },
    setAdvanceId: (state, action) => {
      return { ...state, advanceId: action.payload };
    },
    setAssignId: (state, action) => {
      return { ...state, assignId: action.payload };
    },
    setData: (state, action) => {
      return { ...state, model: { ...state.model, data: action.payload } };
    },
    setModel: (state: any, action: { payload: any }) => {
      return {
        ...state,
        model: action.payload,
      };
    },
    setPage: paginationReducers.setPage,
    setLoading: screenReducers.setLoading,
    setErrorMessage: screenReducers.setErrorMessage,
    setWarning: screenReducers.setWarning,

    setChildVisible: listReducers.setChildVisible,
    setSelectedId: listReducers.setSelectedId,
    setSearch: listReducers.setSearch,
    clearWindowState: (_: any) => {
      return modelInitialState;
    },
  },
  extraReducers: (builder) => {
    addFetchListStoreFactoryReducer(builder, fetchListThunk);
    addFetchListFieldStoreFactoryReducer(builder, fetchLawyerAssignedListThunk);
    addFetchListFieldStoreFactoryReducer(builder, fetchLawyerOpenedListThunk);
    addFetchListFieldStoreFactoryReducer(builder, fetchPreview);
    addSaveFieldStoreFactoryReducer(builder, advanceProcess);
    addSaveFieldStoreFactoryReducer(builder, assignProcess);

    addFetchIdStoreFactoryReducer(builder, fetchIdThunk, fullfilledFunction);
    addDeleteStoreFactoryReducer(builder, deleteThunk);
    addSaveStoreFactoryReducer(builder, saveThunk);
    addUpdateStoreFactoryReducer(builder, updateThunk);

    addUploadFileStoreFactoryReducer(builder, uploadFileThunk);
  },
});

const fullfilledFunction = (state: any) => {
  state.actualPhase = state.model.phase;

  state.actualPhaseData = state.model.data?.find(
    (x: any) => x.phase._id == state.model.phase._id
  );
  console.log(state.actualPhase);
  state.keyElementArray = state.model.phase.dataFields
    .filter((x: any) => x.contentType == "KEYELEMENT")
    .map((y: any) => ({
      _id: y.keyElement?._id,
      content: y.keyElement?.keyOptions.map((z: any) => ({ _id: z, name: z })),
    }));

  state.pastPhaseData = state.model.data
    ?.filter((x: any) => x.phase._id !== state.model.phase._id)
    .flatMap((x: any) => x.phaseContent);

  const answers = state.model.data
    ?.filter((x: any) => x.phase._id === state.model.phase._id)
    .flatMap((x: any) => x.phaseContent)
    .filter(
      (x: any) =>
        x.field.contentType == "ANSWER" || x.field.contentType === "COUNT"
    );
  state.pastPhaseData = [...state.pastPhaseData, ...answers];

  if (!state.actualPhaseData)
    state.actualPhaseData = {
      phase: { _id: state.actualPhase._id },
      phaseContent: [],
    };

  for (let i = 0; i < state.actualPhase.dataFields.length; i++) {
    let id = -1;
    id = state.actualPhaseData.phaseContent.findIndex(
      (x: any) => x?.field?._id === state.actualPhase.dataFields[i]._id
    );
    if (state.actualPhase.dataFields[i].contentType === "ARRAY" && id < 0) {
      state.actualPhaseData.phaseContent.push({
        children: [],
        content: "{}",
        field: state.actualPhase.dataFields[i],
        key: state.actualPhase.dataFields[i].key,
      });
    } else if (id < 0) {
      state.actualPhaseData.phaseContent.push({
        children: [],
        content: "",
        field: state.actualPhase.dataFields[i],
        key: state.actualPhase.dataFields[i].key,
      });
    }
  }
};
