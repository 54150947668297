import { useEffect } from "react";
import { useAppSelector } from "../../services/stores/hooks/useAppSelector";
import { useAppDispatch } from "../../services/stores/hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";

import { List } from "../../component/list/list";
import {
  setSearch,
  setSelectedId,
  setWarning,
  setPage,
} from "../../services/stores/ProcessStore/actions";
import {
  deleteThunk,
  fetchListThunk,
} from "../../services/stores/ProcessStore/slice";
import Swal from "sweetalert2";

export function ProcessList() {
  const processes = useAppSelector((store) => store.processSlice.list.all);
  const { showWarning, loadingScreen, loadingSearch } = useAppSelector(
    (store) => store.processSlice.screen
  );

  const { pagination } = useAppSelector((store) => store.processSlice);
  const { search } = useAppSelector((store) => store.processSlice.list);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchListThunk({ force: true })).then((result) => {});
  }, []);
  return (
    <div className="row">
      {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}
      <List
        hideDelete
        hideEdit
        // searchbox ---------------------------------------------
        search_input_value={search}
        search_text="Pesquise pelo cliente, advogado fase ou produto."
        onChangeSearchInput={(value) => {
          dispatch(setSearch(value));
        }}
        onSearchButtonClick={() => {
          dispatch(setPage(1));
          dispatch(fetchListThunk({ force: true }));
        }}
        // searchbox ---------------------------------------------
        // additionalButtons={[
        //   { label: "Importar CSV", eventHandler: (e: any) => onImportClick(e) },
        // ]}
        title={"processos"}
        not_found_message={"Nenhum processo encontrado"}
        onManagerActionClick={(id) => {
          navigate("/lawyer-processes/" + id);
        }}
        onChangePagination={(value: number) => {
          dispatch(setPage(value));
          dispatch(fetchListThunk({ force: true }));
        }}
        show_warning={showWarning}
        loading_screen={loadingScreen}
        loading_search={loadingSearch}
        items={processes
          .filter(
            (x: any) =>
              x.client.name.toLowerCase().includes(search.toLowerCase()) ||
              x.user.name.toLowerCase().includes(search.toLowerCase()) ||
              x.phase.name.toLowerCase().includes(search.toLowerCase()) ||
              x.phase.product.name?.name
                ?.toLowerCase()
                .includes(search.toLowerCase())
          )
          .map((x) => {
            return {
              ...x,
              status:
                x.status == "OPENED"
                  ? "ABERTO"
                  : x.status == "ASSIGNED"
                  ? "ATRIBUIDO"
                  : x.status,
            };
          })}
        pagination={{
          last_page: pagination?.totalPages,
          current_page: pagination?.page,
        }}
        fields={[
          { name: "client.name", type: "text", header: "Cliente" },
          { name: "user.name", type: "text", header: "Advogado" },
          { name: "status", type: "text", header: "Status" },
          { name: "phase.name", type: "text", header: "Fase" },
          { name: "phase.product.name", type: "text", header: "Produto" },
        ]}
      ></List>
    </div>
  );
}
