import { useAppSelector } from "../../services/stores/hooks/useAppSelector";
import {
  setUsername,
  setPassword,
  setName,
  setPasswordConfirmation,
} from "../../services/stores/loginStore/actions";
import { authenticate } from "../../services/stores/auth/async/authenticateThunk";
import { useAppDispatch } from "../../services/stores/hooks/useAppDispatch";
import {
  Form,
  FormControl as Input,
  InputGroup,
} from "react-bootstrap";
import { Attention } from "../../component/base/Attention";

import Bg from "../../assets/images/bg.png";
import Logo from "../../assets/images/logo.png";
import PasswordInput from "../../component/base/PasswordInput";
import DefaultButton from "../../component/base/DefaultButton";
import { useState } from "react";
import { register } from "../../services/stores/auth/async/registerThunk";

export function LoginView() {
  const { username, password, name, passwordConfirmation } = useAppSelector((store) => store.login);
  const { loading, errorMessage } = useAppSelector((store) => store.auth);
  const dispatch = useAppDispatch();
  const [loginRegister, setLoginRegister] = useState("login");
  return (
    <div style={{ height: "100vh" }}>
      <div className="row" style={{ backgroundColor: "white", margin: 0 }}>
        <div
          className="col-md-6 col-12 col-sm-12 col-lg-4 col-xl-4"
          style={{ margin: 0, padding: 0 }}
        >
          <div className="card" style={{ height: "100vh", margin: 0 }}>
            <div
              className="card-body"
              style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
            >
              <br />

              {
                <div className="text-center">
                  <a className="logo">
                    <img
                      style={{ maxHeight: 70 }}
                      src={Logo}
                      height="60"
                      alt="logo"
                    />
                  </a>
                </div>
              }
              <br />
              {loginRegister == "login" && (
                <div
                  style={{ padding: "1rem", paddingBottom: 0, marginTop: 10 }}
                >
                  <h4
                    className="font-18 m-b-5 text-center header-title"
                    style={{ fontSize: 18 }}
                  >
                    Bem vindo !
                  </h4>
                  <p
                    className="text-muted text-center"
                    style={{ fontSize: 14 }}
                  >
                    Faça login para continuar.
                  </p>

                  <form
                    className="form-horizontal m-t-30"
                    onSubmit={() =>
                      dispatch(authenticate({ username, password }))
                    }
                  >
                    <InputGroup>
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          E-mail <Attention />
                        </Form.Label>
                        <Input
                          type="email"
                          style={{ fontSize: 14 }}
                          placeholder="E-mail"
                          value={username}
                          onChange={(e) =>
                            dispatch(setUsername(e.target.value))
                          }
                          required
                          // title="Coloque o e-mail do usuário"
                          autoComplete="new-password"
                        />
                      </div>
                    </InputGroup>

                    <InputGroup>
                      <PasswordInput
                        label="Senha"
                        placeholder="Digite sua senha"
                        className="flex1"
                        value={password}
                        OnChangeText={(e: any) =>
                          dispatch(setPassword(e.target.value))
                        }
                        required
                        // title="Coloque a senha do usuário"
                      />
                    </InputGroup>

                    {/* <div className="form-group row">
                                            <div className="col-12">
                                                <label >Senha</label>
                                                <input id="password" type="password"
                                                    value={this.state.password}
                                                    onChange={this.changePassword.bind(this)}
                                                    className="form-control" name="password"
                                                    required autoComplete="current-password" />



                                            </div>

                                        </div> */}

                    <div className="form-group row m-t-20 m-b-10">
                      <div className="col-sm-12">
                        <span
                          className="invalid-feedback"
                          style={{ display: "flex", justifyContent: "center" }}
                          role="alert"
                        >
                          <strong
                            style={{ textAlign: "center", fontSize: "0.8rem" }}
                          >
                            {errorMessage}
                          </strong>
                        </span>
                      </div>
                      <div className="col-sm-12 text-right">
                        <DefaultButton
                          type="submit"
                          bg="primary"
                          width="100%"
                          height="3rem"
                          style={{ marginTop: "15px" }}
                          text="Entrar"
                          loadingtext="Entrando..."
                          loading={loading}
                        />
                      </div>
                      <div
                        className="col-sm-12 text-right"
                        style={{ marginTop: "10px" }}
                      >
                        {/* <button className="btn btn-primary w-md waves-effect waves-light" style={{ width: '100%', marginTop: '15px', height: '3rem', backgroundColor: this.state.faculdade != null ? this.state.faculdade.primaria : '' }} type="submit">Entrar</button> */}
                        <DefaultButton
                          type="outlined"
                          bg="secondary"
                          width="100%"
                          height="3rem"
                          style={{ marginTop: "15px" }}
                          text="Novo Usuário"
                          onClick={(e) => {
                            setLoginRegister("register");
                            return undefined;
                          }}
                        />
                      </div>
                    </div>

                    <div className="form-group m-t-10 mb-0 row">
                      <div
                        className="col-12 m-t-20"
                        style={{ textAlign: "center" }}
                      >
                        {/* <a href="{{ action('Auth\ForgotPasswordController@showLinkRequestForm',$faculdade->slug) }}">
                                            <i className="mdi mdi-lock"></i>{{ __('Esqueceu sua senha?') }}
                                        </a>  */}
                        {/* {this.state.faculdade == null && <a href="{{ action('Auth\ForgotPasswordController@showLinkRequestForm','admin') }}">
                                                    <i className="mdi mdi-lock"></i>Esqueceu sua senha?
                                        </a>} */}
                      </div>
                    </div>
                  </form>
                </div>
              )}
              {loginRegister == "register" && (
                <div
                  style={{ padding: "1rem", paddingBottom: 0, marginTop: 10 }}
                >
                  <h4
                    className="font-18 m-b-5 text-center header-title"
                    style={{ fontSize: 18 }}
                  >
                    Novo Usuário
                  </h4>
                  <p
                    className="text-muted text-center"
                    style={{ fontSize: 14 }}
                  >
                    Preencha os campos abaixo.
                  </p>

                  <form
                    className="form-horizontal m-t-30"
                    onSubmit={() =>
                      dispatch(register({ name, username, password  }))
                    }
                  >
                    <InputGroup>
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          Nome <Attention />
                        </Form.Label>
                        <Input
                          type="text"
                          style={{ fontSize: 14 }}
                          placeholder="Nome"
                          value={name}
                          onChange={(e) =>
                            dispatch(setName(e.target.value))
                          }
                          required
                        />
                      </div>
                    </InputGroup>
                    <InputGroup>
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>
                          E-mail <Attention />
                        </Form.Label>
                        <Input
                          type="email"
                          style={{ fontSize: 14 }}
                          placeholder="E-mail"
                          value={username}
                          onChange={(e) =>
                            dispatch(setUsername(e.target.value))
                          }
                          required
                          // title="Coloque o e-mail do usuário"
                          autoComplete="new-password"
                        />
                      </div>
                    </InputGroup>
                    <InputGroup>
                      <PasswordInput
                        label="Senha"
                        placeholder="Digite sua senha"
                        className="flex1"
                        value={password}
                        OnChangeText={(e: any) =>
                          dispatch(setPassword(e.target.value))
                        }
                        required
                        // title="Coloque a senha do usuário"
                      />
                    </InputGroup>
                    <InputGroup>
                      <PasswordInput
                        label="Confirmar Senha"
                        placeholder="Confirme sua senha"
                        className="flex1"
                        value={passwordConfirmation}
                        OnChangeText={(e: any) =>
                          dispatch(setPasswordConfirmation(e.target.value))
                        }
                        required
                        // title="Coloque a senha do usuário"
                      />
                    </InputGroup>
                    {/* <div className="form-group row">
                                            <div className="col-12">
                                                <label >Senha</label>
                                                <input id="password" type="password"
                                                    value={this.state.password}
                                                    onChange={this.changePassword.bind(this)}
                                                    className="form-control" name="password"
                                                    required autoComplete="current-password" />



                                            </div>

                                        </div> */}

                    <div className="form-group row m-t-20 m-b-10">
                      <div className="col-sm-12">
                        <span
                          className="invalid-feedback"
                          style={{ display: "flex", justifyContent: "center" }}
                          role="alert"
                        >
                          <strong
                            style={{ textAlign: "center", fontSize: "0.8rem" }}
                          >
                            {errorMessage}
                          </strong>
                        </span>
                      </div>
                      <div className="col-sm-12 text-right">
                        <DefaultButton
                          type="submit"
                          bg="primary"
                          width="100%"
                          height="3rem"
                          style={{ marginTop: "15px" }}
                          text="Registrar"
                          loadingtext="Registrando..."
                          loading={loading}
                        />
                      </div>
                      <div
                        className="col-sm-12 text-right"
                        style={{ marginTop: "10px" }}
                      >
                        <DefaultButton
                          type="outlined"
                          bg="secondary"
                          width="100%"
                          height="3rem"
                          style={{ marginTop: "15px" }}
                          text="Voltar"
                          onClick={(e) => {
                            setLoginRegister("login");
                            return undefined;
                          }}
                        />
                      </div>
                    </div>

                    <div className="form-group m-t-10 mb-0 row">
                      <div
                        className="col-12 m-t-20"
                        style={{ textAlign: "center" }}
                      >
                        {/* <a href="{{ action('Auth\ForgotPasswordController@showLinkRequestForm',$faculdade->slug) }}">
                                            <i className="mdi mdi-lock"></i>{{ __('Esqueceu sua senha?') }}
                                        </a>  */}
                        {/* {this.state.faculdade == null && <a href="{{ action('Auth\ForgotPasswordController@showLinkRequestForm','admin') }}">
                                                    <i className="mdi mdi-lock"></i>Esqueceu sua senha?
                                        </a>} */}
                      </div>
                    </div>
                  </form>
                </div>
              )}

              <div className="text-center" style={{ marginTop: 20 }}>
                <p style={{ fontSize: 14 }}>© 2024 Cover Pay do Brasil Tec. Informação LTDA</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-6 col-sm-6 col-12 col-lg-8 col-xl-8 d-none d-md-block"
          style={{
            overflow: "hidden",
            backgroundImage: `url(${Bg})`,
            backgroundSize: "cover",
            padding: 0,
            margin: 0,
            height: "100vh",
          }}
        ></div>
      </div>
    </div>

    // <Center height="full" _dark={{ bg: "black" }} _light={{ bg: "white" }}>
    //   <Box width="full" p="8">
    //     <Heading>Entrar</Heading>
    //     <FormControl>
    //       <FormControl.Label>Login</FormControl.Label>
    //       <Input
    //         value={username}
    //         onChangeText={(text) => setUsername(text)}
    //         placeholder="Email"
    //         InputLeftElement={
    //           <Icon as={<MaterialIcons name="person" />} size={6} ml={2} />
    //         }
    //       />
    //       <FormControl.ErrorMessage
    //         leftIcon={<WarningOutlineIcon size="xs" />}
    //         m="0"
    //       >
    //         Email inválido
    //       </FormControl.ErrorMessage>
    //     </FormControl>
    //     <FormControl>
    //       <FormControl.Label>Senha</FormControl.Label>
    //       <Input
    //         value={password}
    //         onChangeText={(text) => setPassword(text)}
    //         placeholder="Senha"
    //         InputLeftElement={
    //           <Icon as={<MaterialIcons name="lock" />} size={6} ml={2} />
    //         }
    //       ></Input>
    //       <FormControl.HelperText m="0">
    //         A senha deve ter oito letras.
    //       </FormControl.HelperText>
    //     </FormControl>
    //     {errorMessage && (
    //       <Center>
    //         <Text mx={4} fontSize={"xs"} color={"red.600"} mt={4}>
    //           {errorMessage}
    //         </Text>
    //       </Center>
    //     )}
    //     <Button onPress={() => dispatch(authenticate())} mt={4}>
    //       Entrar
    //     </Button>
    //     <Checkbox mt={2} alignItems={"right"} background={"red"} value="agreed">
    //       Aceito tudo
    //     </Checkbox>

    //     {/* <HStack alignItems={"center"}>
    //       <Text>Dark</Text>
    //       <Switch
    //         isChecked={colorMode === "light"}
    //         onToggle={toggleColorMode}
    //       ></Switch>
    //       <Text>Light</Text>
    //     </HStack> */}
    //   </Box>
    // </Center>
  );
}
