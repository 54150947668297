import { modelSlice } from "./slice";

export const {
  setSearch,
  setWarning,
  setSelectedId,
  clearWindowState,
  setLoading,
  setStage,
  setClient,
  setErrorMessage,
  setPage,
  setModel,
  setProduct,
  setPhase,
  setAdvanceId,
  setAssignId,
  setUser,
  setKey,
  setData,
  setPhaseContentField,
  removeContentChild,
  addContentToChildren,
  setPhaseContentFieldKey,
  setContentToModel,
} = modelSlice.actions;
