import { Client } from "./Client";
import { Phase } from "./Phase";
import { Product } from "./Product";
import { User } from "./User";

export const Process = {
  _id: "",
  stage: "",
  key: "",
  client: Client,
  fields: [],
  product: Product,
  phase: Phase,
  user: User,
  data: new Array(),
  media: new Array(),
};
