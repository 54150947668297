import { modelSlice } from "./slice";

export const {
  setSearch,
  setWarning,
  setSelectedId,
  clearWindowState,
  setLoading,
  setName,
  setDescription,
  setType,
  setErrorMessage,
  setPage,
  setModel,
  setProduct,
  setOrder,
  setQuery,
  setContentType,
  setKey,
  setHasPreview,
  setFieldsJSON,
  addText,
  removeText,
  setText,
  addDocument,
  removeDocument,
} = modelSlice.actions;
