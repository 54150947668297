import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialState";

export const modelSlice = createSlice({
  name: "login",
  initialState: initialState,
  reducers: {
    setName: (state, action: PayloadAction<string>) => {
      return { ...state, name: action.payload };
    },
    setUsername: (state, action: PayloadAction<string>) => {
      return { ...state, username: action.payload };
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      return { ...state, loading: action.payload };
    },
    setPassword: (state, action: PayloadAction<string>) => {
      return { ...state, password: action.payload };
    },
    setPasswordConfirmation: (state, action: PayloadAction<string>) => {
      return { ...state, passwordConfirmation: action.payload };
    },
  }
});
